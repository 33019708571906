import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import ReviewItem from './ReviewItem';
import s from './Reviews.style';

class Reviews extends Component {
  state = {};

  render() {
    return (
      <div className={ css(s.reviewsWrapper) }>
        <h2 className={ css(s.reviewsTitle) }>Rólam mondták</h2>
        <div className={ css(s.reviewsWrapperInner) }>
          <ReviewItem
            image={ '/images/reviews/review-moni.jpg' }
            title={ 'Móni' }
            text={ 'Ági eszméletlen ügyes, és kiváló sminkekkel dolgozik. Eddig bármilyen alkalommal voltam nála az alkalomhoz illő sminket mindig nagyon eltalálta.' }
          />
          <ReviewItem
            image={ '/images/reviews/review-sandra.jpg' }
            title={ 'Sandra' }
            text={ 'Korábban sosem sminkeltem magam, így az esküvőm előtt kicsit tétován álltam a kérdés előtt, de Ági minden félelmemet eloszlatta profizmusával, kedvességével. Az esküvő előtt egy egyéni sminktanácsadásra és oktatásra is sort kerítettünk. Számos hasznos tanáccsal, tippel, termékajánlással és ami a legfontosabb, használható tudással távoztam Ágitól, így mindenkinek csak ajánlani tudom!' }
          />
          <ReviewItem
            image={ '/images/reviews/review-andi.jpg' }
            title={ 'Andi' }
            text={ 'Ági nagyon profi, felkészült sminkes, nála nincs lehetetlen :) Szívből ajánlom akár alkalmi sminkre, akár személyes sminktanácsadásra, de a tavaszi tuning is szuper kezdeményezés!' }
          />
          <ReviewItem
            image={ '/images/reviews/review-niki.jpg' }
            title={ 'Niki' }
            text={ 'Gyönyörű sminket kaptam Ágitol az esküvőmre. Barátságos, végtelenül kedves és a precizitás jellemzi a munkáját! Csak ajánlani tudom, csalódni nem fogsz. Ezerszer is Őt választanám. Köszönöm!' }
          />
        </div>
      </div>
    );
  }
}

export default Reviews;
