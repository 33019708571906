import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite/no-important';
import { mergeStyle } from '../utils';

const s = StyleSheet.create({
  root: {
    display: 'block',
    position: 'relative',

    ':after': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      content: '""',
    },
  },
  svg: {
    fill: 'currentColor',
    stroke: 'currentColor',
    width: '100%',
    height: '100%',
  },
  svgNoStroke: {
    fill: 'currentColor',
    stroke: 'none',
    width: '100%',
    height: '100%',
  },
});

const getUse = name => `<use xlink:href="#icon-${name}" />`;

const Icon = ({ name, className = '', noStroke = false }) => {
  const iconName = name.value ? name.value : name;
  return (
    <i className={ mergeStyle(css(s.root), className) }>
      <svg
        className={ css(noStroke ? s.svgNoStroke : s.svg) }
        dangerouslySetInnerHTML={ { __html: getUse(iconName) } } />
    </i>
  );
};

Icon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  noStroke: PropTypes.bool,
};

export default Icon;
