import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import Header from './Header';
import Footer from './Footer';
import s from './Page.style';

class Page extends Component {
  state = {};

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <div className={ css(s.page) }>
        <Header />
        <main className={ css(s.mainWrapper) }>
          { this.props.children }
        </main>
        <Footer />
      </div>
    );
  }
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
};

export default Page;
