import { BREAKPOINTS } from './config';

export const COLORS = {
  WHITE: '#fff',
  PINK: 'rgba(255, 41, 132, 1)',
  PINK2: '#d53379',
  PINK3: '#d61b82',
  PINK_DARK: '#b3004c',
  PINK_DARKER: '#800036',
  BLACK: '#000',
  BLACK30: 'rgba(0, 0, 0, .3)',
  BLACK40: 'rgba(0, 0, 0, .4)',
  BLACK50: 'rgba(0, 0, 0, .5)',
  BLACK60: 'rgba(0, 0, 0, .6)',
  GRAY: '#898989',
  GRAY2: '#666',
  GRAY3: '#e7ecee',
  GRAY4: '#f8f8f8',
  GRAY5: '#333',
  TRANSPARENT: 'transparent',
};

export const CENTER = 'CENTER';

export const POSITION = {
  RELATIVE: 'relative',
  ABSOLUTE: 'absolute',
  FIXED: 'fixed',
};

export const DISPLAY = {
  FLEX: 'flex',
  NONE: 'none',
  BLOCK: 'block',
};

export const Size = (w, h) => ({
  width: w,
  height: h ? h : w,
});

export const FONTS = {
  OPEN_SANS: {
    fontFamily: '"Open Sans", sans-serif',
  },
};

export const BASE_SPACING = 22;
export const MOBILE_BASE_SPACING = 16;

export const PSEUDO = {
  BEFORE: ':before',
  AFTER: ':after',
};

export const MEDIA_QUERIES = {
  DESKTOP: `@media (min-width: ${BREAKPOINTS.MOBILE}px)`,
  MOBILE: `@media (max-width: ${BREAKPOINTS.MOBILE - 1}px)`,
};

const DOTS_SIZE = 1;
const DOTS_SPACING = 2;

const gradient1 = (
  `linear-gradient(90deg, ${COLORS.BLACK_1} ${DOTS_SPACING - DOTS_SIZE}px, transparent 1%) center`
);
const gradient2 = (
  `linear-gradient(${COLORS.BLACK_1} ${DOTS_SPACING - DOTS_SIZE}px, transparent 1%) center, transparent`
);
export const DOT_BACKGROUND = {
  background: `${gradient1}, ${gradient2}`,
  backgroundSize: `${DOTS_SPACING}px ${DOTS_SPACING}px`,
};


