import { StyleSheet } from 'aphrodite/no-important';
import { POSITION, COLORS, FONTS } from '../styles';

export default StyleSheet.create({

  contentWrapper: {
    position: POSITION.RELATIVE,
    display: 'flex',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    ...FONTS.OPEN_SANS,
    background: COLORS.WHITE,

    '@media screen and (max-width: 1024px)': {
      flexWrap: 'wrap',
    },

  },

  workCategory: {
    position: 'relative',
    flex: '1 1',
    flexBasis: '25%',
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'flex-basis 500ms ease-in-out',

    ':nth-child(n) > img': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transition: 'transform 500ms ease-in-out',
    },

    '@media screen and (min-width: 1025px)': {

      ':hover': {
        flexBasis: '40%',
      },

    },

    '@media screen and (max-width: 1024px)': {
      flexBasis: '50%',
      height: '50%',

      ':nth-child(n):hover > img': {
        transform: 'scale(1.05)',
      },

    },

  },

  workTitle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding: 10,
    ...FONTS.OPEN_SANS,
    zIndex: 10,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: COLORS.WHITE,
    background: COLORS.PINK,
    transform: 'translate(-50%, -50%)',
  },

});
