import { POSITION, COLORS, FONTS } from '../styles';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({

  galleryCarouselMainWrapper: {
    position: POSITION.RELATIVE,
    width: 'auto',
    minWidth: '100%',
    height: '40vh',
    minHeight: 200,
    maxHeight: 450,
    padding: '40px 0 0',
    margin: '0 0 60px',
    ...FONTS.OPEN_SANS,
    background: COLORS.WHITE,

    '@media (max-width: 768px)': {
      padding: '20px 0 0',
      margin: '0 0 40px',
    },

  },

  galleryCarouselMainWrapperFullBleed: {
    width: '100vw',
    height: '60vh',
    maxHeight: 700,
    transform: 'translateX(calc((-100vw + 768px - 80px) / 2))',

    '@media (max-width: 768px)': {
      maxHeight: 300,
      transform: 'translateX(-40px)',
    },

  },

  galleryCarouselWrapper: {
    position: POSITION.RELATIVE,
    display: 'flex',
    width: '100%',
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    margin: '0 0 30px',
    boxSizing: 'content-box',
    transitionTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
  },

  galleryCarouselSlideWrapper: {
    position: POSITION.RELATIVE,
    display: 'flex',
    flexShrink: 0,
    width: '100%',
    height: '100%',
    background: COLORS.GRAY2,
  },

  galleryCarouselSlide: {
    position: POSITION.RELATIVE,
    flexShrink: 0,
    width: '100%',
    height: '100%',
    background: COLORS.GRAY2,
  },

  galleryCarouselImage: {
    objectFit: 'cover',
  },

  galleryCarouselButton: {
    position: POSITION.ABSOLUTE,
    width: 80,
    height: 180,
    top: '50%',
    padding: 0,
    zIndex: 15,
    border: 0,
    cursor: 'pointer',
    outline: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textIndent: '110%',
    background: 'none',
    transform: 'translateY(-50%)',

    '@media (max-width: 768px)': {
      display: 'none',
    },

    ':hover:before': {
      background: COLORS.PINK,
    },

    ':hover:after': {
      background: COLORS.PINK,
    },

    ':before': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: 8,
      height: 90,
      top: 2,
      transformOrigin: 'bottom center',
      background: COLORS.PINK_DARK,
      transition: 'background-color .3s ease-out, transform .3s ease-out',
    },

    ':after': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: 8,
      height: 90,
      bottom: 2,
      transformOrigin: 'top center',
      background: COLORS.PINK_DARK,
      transition: 'background-color .3s ease-out, transform .3s ease-out',
    },

  },

  galleryCarouselButtonLeft: {
    left: -120,

    ':before': {
      left: 0,
      transform: 'translateX(4px) rotateZ(28deg)',
    },

    ':after': {
      left: 0,
      transform: 'translateX(4px) rotateZ(-28deg)',
    },

    ':hover:before': {
      transform: 'translateX(0) rotateZ(28deg)',
    },

    ':hover:after': {
      transform: 'translateX(0) rotateZ(-28deg)',
    },

    '@media (max-width: 1200px)': {
      left: 20,
    },

  },

  galleryCarouselButtonRight: {
    right: -120,

    ':before': {
      right: 0,
      transform: 'translateX(-4px) rotateZ(-28deg)',
    },

    ':after': {
      right: 0,
      transform: 'translateX(-4px) rotateZ(28deg)',
    },

    ':hover:before': {
      transform: 'translateX(0) rotateZ(-28deg)',
    },

    ':hover:after': {
      transform: 'translateX(0) rotateZ(28deg)',
    },

    '@media (max-width: 1200px)': {
      right: 20,
    },

  },

  galleryCarouselButtonLeftFullBleed: {
    left: 80,
  },

  galleryCarouselButtonRightFullBleed: {
    right: 80,
  },

});
