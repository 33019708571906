import { StyleSheet } from 'aphrodite/no-important';
import { POSITION, COLORS, FONTS } from '../styles';

export default StyleSheet.create({

  contentBlock: {
    position: POSITION.RELATIVE,
    margin: '0 0 20px',

    ':after': {
      position: 'absolute',
      content: '""',
      bottom: -10,
      left: '40%',
      right: '40%',
      height: 1,
      background: COLORS.PINK,
    },

  },

  contentBlockWithoutDecor: {

    ':after': {
      display: 'none',
    },

  },

  contentWrapper: {
    position: POSITION.RELATIVE,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    ...FONTS.OPEN_SANS,
    background: COLORS.WHITE,
  },

  contentWrapperInner: {
    position: POSITION.RELATIVE,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: 768,
    margin: '0 auto',
    padding: '40px 40px',
    ...FONTS.OPEN_SANS,
    background: COLORS.WHITE,

    ':nth-child(n) > p': {
      padding: 0,
      margin: '0 0 20px',
      fontSize: 16,
      lineHeight: 1.5,
      color: COLORS.GRAY5,

      ':nth-child(1n) > b': {
        fontWeight: 700,
      },

      '@media (max-width: 768px)': {
        fontSize: 14,
      },

      '@media (max-width: 640px)': {
        fontSize: 13,
      },

    },

    ':nth-child(n) > h2': {
      padding: '10px 0 0',
      margin: '0 0 10px',
      fontSize: 22,
      fontWeight: 600,
      lineHeight: 1.4,
      color: COLORS.BLACK,

      ':nth-child(1n) > b': {
        fontWeight: 700,
      },

      '@media (max-width: 768px)': {
        fontSize: 18,
      },

      '@media (max-width: 640px)': {
        fontSize: 15,
      },

    },

    ':nth-child(n) > h6': {
      fontSize: 19,
      margin: '0 0 10px',

      '@media (max-width: 768px)': {
        fontSize: 17,
      },

      '@media (max-width: 640px)': {
        fontSize: 14,
      },

    },

    ':nth-child(n) > ul': {
      position: POSITION.RELATIVE,
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },

    ':nth-child(n) > ul > li': {
      position: POSITION.RELATIVE,
      padding: '0 0 0 40px',
      margin: '0 0 20px',
      fontSize: 16,
      lineHeight: 1.5,
      color: COLORS.GRAY5,

      ':before': {
        position: POSITION.ABSOLUTE,
        content: '""',
        width: 6,
        height: 6,
        top: 10,
        left: 20,
        background: COLORS.PINK,
      },

      ':nth-child(1n) > b': {
        fontWeight: 700,
      },

      ':nth-child(1n) > a': {
        textDecoration: 'underline',
        color: COLORS.PINK,
      },

      '@media (max-width: 768px)': {
        padding: '0 0 0 30px',
        fontSize: 14,

        ':before': {
          top: 8,
          left: 10,
        },

      },

      '@media (max-width: 640px)': {
        padding: '0 0 0 20px',
        fontSize: 13,

        ':before': {
          top: 5,
          left: 5,
        },

      },
    },

  },

  contentTitle: {
    padding: '10px 0 0',
    margin: '0 0 10px',
    fontSize: 22,
    fontWeight: 600,
    lineHeight: 1.4,
    color: COLORS.BLACK,

    ':nth-child(1n) > b': {
      fontWeight: 700,
    },

    '@media (max-width: 768px)': {
      fontSize: 18,
    },

    '@media (max-width: 640px)': {
      fontSize: 15,
    },

  },

  contentSubTitle: {
    fontSize: 19,
    margin: '0 0 30px',

    '@media (max-width: 768px)': {
      fontSize: 17,
    },

    '@media (max-width: 640px)': {
      fontSize: 14,
    },

  },

  contentUnorderedList: {
    position: POSITION.RELATIVE,
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },

  contentUnorderedListItem: {
    position: POSITION.RELATIVE,
    padding: '0 0 0 40px',
    margin: '0 0 20px',
    fontSize: 16,
    lineHeight: 1.5,
    color: COLORS.GRAY5,

    ':before': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: 6,
      height: 6,
      top: 10,
      left: 20,
      background: COLORS.PINK,
    },

    ':nth-child(1n) > b': {
      fontWeight: 700,
    },

    ':nth-child(1n) > a': {
      textDecoration: 'underline',
      color: COLORS.PINK,
    },

    '@media (max-width: 768px)': {
      padding: '0 0 0 30px',
      fontSize: 14,

      ':before': {
        top: 8,
        left: 10,
      },

    },

    '@media (max-width: 640px)': {
      padding: '0 0 0 20px',
      fontSize: 13,

      ':before': {
        top: 5,
        left: 5,
      },

    },
  },

  contentParagraph: {
    padding: 0,
    margin: '0 0 20px',
    fontSize: 16,
    lineHeight: 1.5,
    color: COLORS.GRAY5,

    ':nth-child(1n) > b': {
      fontWeight: 700,
    },

    '@media (max-width: 768px)': {
      fontSize: 14,
    },

    '@media (max-width: 640px)': {
      fontSize: 13,
    },

  },

  contentInfo: {
    padding: 0,
    margin: '0 0 20px',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.5,
    color: COLORS.PINK,

    ':nth-child(1n) > b': {
      fontWeight: 700,
    },

    '@media (max-width: 768px)': {
      fontSize: 14,
    },

    '@media (max-width: 640px)': {
      fontSize: 13,
    },

  },

});
