import { POSITION, FONTS, COLORS } from '../styles';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({

  heroWrapper: {
    position: POSITION.RELATIVE,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%',
    height: '70vh',
    minHeight: 300,
    maxHeight: 600,
    ...FONTS.OPEN_SANS,
    background: COLORS.WHITE,

    '@media (max-width: 768px)': {
      height: '40vh',
      minHeight: 250,
      maxHeight: 450,
    },

    ':after': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: '100%',
      height: 80,
      bottom: 0,
      left: 0,
      zIndex: 20,
      transform: 'rotateZ(0deg)',
      transformOrigin: '100% 0 0',
      background: `linear-gradient(to top right, ${COLORS.WHITE} 50%, ${COLORS.TRANSPARENT} 51%)`,

      '@media (max-width: 768px)': {
        display: 'none',
      },

    },

    ':before': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: '100%',
      height: '100%',
      zIndex: 10,
      opacity: .3,
      background: 'repeating-linear-gradient(-62deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4) 2px, transparent 2px, transparent 4px)',

      '@media (max-width: 768px)': {
        background: 'repeating-linear-gradient(-62deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4) 2px, transparent 2px, transparent 3px)',
      },

    },

  },

  heroImage: {
    position: POSITION.ABSOLUTE,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1,
    objectFit: 'cover',
  },

  blogListWrapper: {
    position: POSITION.RELATIVE,
    width: '100%',
    height: 'auto',
  },

  blogListWrapperInner: {
    position: POSITION.RELATIVE,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    width: '100%',
    maxWidth: 1200,
    margin: '0 auto',
    padding: '40px 10px 80px',
    ...FONTS.OPEN_SANS,
    textAlign: 'left',

    '@media (max-width: 768px)': {
      padding: '10px 10px 20px',
    },

  },

});
