import React, { Component } from 'react';
import HomeCarousel from '../components/HomeCarousel';
import Services from '../components/Services';
import Reviews from '../components/Reviews';
import Map from '../components/Map';

class Home extends Component {
  state = {};

  render() {
    return (
      <section>
        <HomeCarousel />
        <Services />
        <Reviews />
        <Map />
      </section>
    );
  }
}

export default Home;
