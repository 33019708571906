import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import Swiper from 'react-id-swiper/lib/custom';
import s from './GalleryCarousel.style';

class GalleryCarousel extends Component {
  constructor(props) {
    super(props);
    this.goNext = this.goNext.bind(this);
    this.goPrev = this.goPrev.bind(this);
    this.swiperItem = React.createRef();
  }

  goNext() {
    if (this.swiperItem) {
      this.swiperItem.current.swiper.slideNext();
    }
  }

  goPrev() {
    if (this.swiperItem) {
      this.swiperItem.current.swiper.slidePrev();
    }
  }

  render() {
    const gallerySwiperParams = {
      containerClass: css(s.galleryCarouselWrapper),
      wrapperClass: css(s.galleryCarouselSlideWrapper),
      slideClass: css(s.galleryCarouselSlide),
      spaceBetween: 0,
      loop: true,
    };

    return (
      <div className={
        css(
          s.galleryCarouselMainWrapper,
          this.props.isFullBleed ? s.galleryCarouselMainWrapperFullBleed : '',
        )
      }>
        <Swiper { ...gallerySwiperParams } ref={ this.swiperItem }>
          { this.props.images.map((image, id) => {
            return (
              <img
                alt=""
                key={ `gitem-${ id }` }
                className={ css(s.galleryCarouselImage) }
                src={ process.env.PUBLIC_URL + image }
              />
            );
          }) }
        </Swiper>
        <button
          className={ css(
            s.galleryCarouselButton, s.galleryCarouselButtonLeft,
            this.props.isFullBleed ? s.galleryCarouselButtonLeftFullBleed : '',
          ) }
          onClick={ this.goPrev }>Prev
        </button>
        <button
          className={ css(
            s.galleryCarouselButton, s.galleryCarouselButtonRight,
            this.props.isFullBleed ? s.galleryCarouselButtonRightFullBleed : '',
          ) }
          onClick={ this.goNext }>Next
        </button>
      </div>
    );
  }
}

GalleryCarousel.defaultProps = {
  isFullBleed: true,
};

GalleryCarousel.propTypes = {
  images: PropTypes.array,
  isFullBleed: PropTypes.bool,
};

export default GalleryCarousel;
