import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import { Link } from 'react-router-dom';
import s from './BlogItem.style';

class BlogItem extends Component {
  state = {
    hover: false,
    isNew: false,
  };

  componentDidMount() {
    const rawDate = this.props.time && this.props.time.split(' ') || [];
    const year = rawDate[0].slice(0, -1);
    const month = BlogItem.convertMonths(rawDate[1]);
    const day = rawDate[2].slice(0, -1);

    const now = new Date();
    const limit = new Date(now.setDate(now.getDate() - 3));
    const published = new Date(year, month, day);


    if (limit < published) {
      this.setState({
        isNew: true,
      });
    }
  }

  static convertMonths(month) {
    let monthNumber;

    switch (month.toLowerCase()) {
      case 'január':
        monthNumber = '0';
        break;
      case 'február':
        monthNumber = '1';
        break;
      case 'március':
        monthNumber = '2';
        break;
      case 'április':
        monthNumber = '3';
        break;
      case 'május':
        monthNumber = '4';
        break;
      case 'június':
        monthNumber = '5';
        break;
      case 'július':
        monthNumber = '6';
        break;
      case 'augusztus':
        monthNumber = '7';
        break;
      case 'szeptember':
        monthNumber = '8';
        break;
      case 'október':
        monthNumber = '9';
        break;
      case 'november':
        monthNumber = '10';
        break;
      case 'december':
        monthNumber = '11';
        break;
    }

    return monthNumber;
  }

  onMouseOut = () => {
    this.setState({ hover: false });
  };

  onMouseOver = () => {
    this.setState({ hover: true });
  };

  render() {
    return (
      <div
        className={ css(s.blogItemWrapper) }
        onMouseOver={ this.onMouseOver }
        onMouseOut={ this.onMouseOut }
      >
        <Link
          to={ '/blog/' + this.props.url }
          className={
            css(
              s.blogItemWrapperInner,
              this.state.hover ? s.blogItemWrapperInnerHover : '',
            )
          }
        >
          { this.state.isNew &&
          <div className={ css(s.blogItemRibbon) }>
            <span className={ css(s.blogItemRibbonText) }>Új</span>
          </div>
          }
          <div
            className={
              css(
                s.blogItemImageWrapper,
                this.state.hover ? s.blogItemImageWrapperHover : '',
              )
            }
          >
            <img
              className={ css(s.blogItemImage) }
              src={ process.env.PUBLIC_URL + this.props.image }
            />
            <div className={ css(s.blogItemTag) }>{ this.props.tags }</div>
          </div>
          <div className={ css(s.blogItemContent) }>
            <h3 className={ css(s.blogItemTitle) }>{ this.props.title }</h3>
            <time className={ css(s.blogItemPublished) }>{ this.props.time }</time>
            <p className={ css(s.blogItemLead) }>{ this.props.lead }</p>
            <div className={ css(s.blogItemLink) }>Tovább</div>
          </div>
        </Link>
      </div>
    );
  }
}

BlogItem.propTypes = {
  image: PropTypes.string,
  tags: PropTypes.string,
  title: PropTypes.string,
  lead: PropTypes.string,
  time: PropTypes.string,
  url: PropTypes.string,
};

export default BlogItem;
