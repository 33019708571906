import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import ServiceItem from './ServiceItem';
import s from './Services.style';

class Services extends Component {
  state = {};

  render() {
    return (
      <div className={ css(s.servicesWrapper) }>
        <h2 className={ css(s.servicesTitle) }>Szolgáltatásaim</h2>
        <div className={ css(s.serviceWrapperInner) }>
          <ServiceItem
            image={ 'images/services/tanfolyam.jpg' }
            title={ 'Alap sminkes tanfolyam' }
            lead={ 'Használható sminktudás' }
            description={
              <React.Fragment>
                <p>4 hónapos a tanfolyam, 1 alkalom 4 órás.</p>
                <p>Tanulóimnak egyszeri <b>40%-os kedvez-<br />ményes vásárlásra</b> van lehetőségük
                   a MAC-ben.
                </p>
                <p>Érdekel a sminkes szakma, de el vagy veszve, hogy melyik tanfolyamot válaszd?
                   Annyit ígérhetek, hogy olyan tudást kapsz Tőlem, amivel magabiz-<br />tosan fogsz
                   tudni sminkelni.</p>
                <p>Semmi sallang, semmi felesleges információ. Kizárólag tisztán használható
                   tudás! Segítek abban is, hogy hogyan indulj el a szakmában. (Marketing
                   stratégiák, brand építés, stb.)</p>
                <p>További részletekért keress privát üzenetben.</p>
              </React.Fragment>
            }
            time={ '16 x 4 óra ' }
            price={ '360 000 Ft' }
          />
          <ServiceItem
            image={ 'images/services/workshop.jpg' }
            title={ 'Fix tematikájú workshopok sminkeseknek' }
            lead={ 'Sminkeseknek, kozmetikusoknak' }
            description={
              <React.Fragment>
                <p>Csoportos workshopok végzett
                   sminkeseknek / kozmetikusoknak / szépségtanácsadóknak.</p>
                <p>Milyen workshop témák vannak:</p>
                <ul>
                  <li>Esküvői smink</li>
                  <li>Facechart</li>
                  <li>Színelmélet és színkorrekció</li>
                  <li>Szemöldök</li>
                  <li>Kontúrozás és highlightolás, acformák elemzése</li>
                  <li>Szemsminkek, liner technikák, szemformák elemzése</li>
                  <li>Alap SFX (horzsolások, sebek, hegek, golyó lőtte sebek, vágások, zúzódások)
                  </li>
                </ul>
                <p>További részletekért keress privát üzenetben.</p>
              </React.Fragment>
            }
            time={ '6 óra ' }
            price={ '30e-45e Ft' }
          />
          <ServiceItem
            image={ 'images/services/tovabbkepzes.jpg' }
            title={ 'Egyéni továbbképzés sminkeseknek' }
            lead={ 'Továbbképzés' }
            description={
              <React.Fragment>
                <p>Végzett sminkes / kozmetikus / szépségtanácsadó vagy, de szeretnéd kicsit
                   frissíteni a tudásodat, több technikát elsajátítani úgy, hogy teljesen rád legyen
                   szabva az oktatási anyag?</p>
                <p>Egyszemélyes találkozó alkalmával egyeztetjük az elképzeléseidet, ami alapján
                   összeállítok Neked egy tematikát, becsülök rá időt és elküldöm az ajánlatomat.
                   Természetesen ezen mindig lehet módosítani. Aztán indulhat is a menet! ;)</p>
                <p>1 alkalom maximum 6 óra.</p>
                <p>További részletekért keress privát üzenetben.</p>
              </React.Fragment>
            }
            time={ '4-6 óra ' }
            price={ '8 000 Ft / óra' }
          />
          <ServiceItem
            image={ 'images/services/sminktanacsadas.jpg' }
            title={ 'Egyéni sminktanácsadás és oktatás' }
            lead={ 'Személyre szabva, tippek és trükkök' }
            description={
              <React.Fragment>
                <p>Maximálisan személyre van szabva, nem én mondom meg, hogy mit fogsz tanulni,
                   hanem Te mondod meg nekem, hogy mit szeretnél elsajátítani. Az oktatást követően
                   részletes összefoglalót kapsz a tanultakról, minden apró trükkről.</p>
                <p>Ha szeretnél jobban / gyorsabban / tudatosabban sminkelni, szeretnél olyan
                   trükköket eltanulni, amivel megkönnyíted a reggeli készülődést vagy szeretnél
                   kicsit jobban odafigyelni a megjelenésedre, az arcápolási rutinodra, akkor
                   az <b>Egyéni sminktanácsadás</b> Neked való.
                </p>
                <p>További részletekért keress privát üzenetben.</p>
              </React.Fragment>
            }
            time={ '3-4 óra' }
            price={ '30 000 Ft' }
          />
          <ServiceItem
            image={ 'images/services/alkalmi-smink.jpg' }
            title={ 'Alkalmi smink' }
            lead={ 'Hivatalos eseményre, buliba, esküvőre' }
            description={
              <React.Fragment>
                <p>Fontos eseményre készülsz? Esküvőre vagy hivatalos? Bulizni mentek a csajokkal?
                   Csak pár olyan alkalom, amikor jól jönne egy profi smink.</p>
                <p>Alkalmi smink kategóriába tartozik:</p>
                <ul>
                  <li>Nappali smink</li>
                  <li>Esti smink</li>
                  <li>Szalagavató smink</li>
                  <li>Smink fotózásra kiszállás nélkül</li>
                  <li>Smink fitness versenyre kiszállás nélkül</li>
                </ul>
                <p>A műszempilla felhelyezése feláras.</p>
              </React.Fragment>
            }
            time={ '1 óra' }
            price={ '12 000 Ft' }
          />
          <ServiceItem
            image={ 'images/services/csoportos.jpg' }
            title={ 'Csoportos tanácsadás' }
            lead={ 'Tanácsadás' }
            description={
              <React.Fragment>
                <p>Átfogó szépségápolási és sminktanácsadás, ami tökéletes program lehet
                   lánybúcsúra, csapatépítőre, csajos programnak.</p>
                <p>A szolgáltatás időtartama 2-4 óra között a létszámtól függően.</p>
                <p>1-4 fő között a tanácsadás ára 5.000 Ft/fő, 5 főtől pedig 4.000 Ft/fő</p>
                <p>Mi van az árban?</p>
                <ul>
                  <li>Összefoglaló a tanultakról</li>
                  <li>Welcome drink</li>
                  <li>Nasi</li>
                </ul>
              </React.Fragment>
            }
            time={ '2-4 óra ' }
            price={ '6e-8e Ft / fő' }
          />
          <ServiceItem
            image={ 'images/services/sfx.jpg' }
            title={ 'Alap SFX' }
            lead={ 'Halloween-i, farsangi smink' }
            description={
              <React.Fragment>
                <p>Halloween-i vagy farsangi buliba vagy hivatalos és fejben már megvan a karakter,
                   de kéne egy király smink, esetleg hegek, sebek, vágások hozzá?</p>
                <p>Akkor ez neked van kitalálva! ;)</p>
              </React.Fragment>
            }
            time={ '1,5 óra ' }
            price={ '15 000 Ft' }
          />
          <ServiceItem
            image={ 'images/services/tesztfoto.jpg' }
            title={ 'Teszt fotózás / Portfólió fotózás' }
            lead={ '' }
            description={
              <React.Fragment>
                <p>1 smink - 10 000 Ft</p>
                <p>+1 ráépített smink - 12 500 Ft</p>
                <p>+2 ráépített smink - 15 000 Ft</p>
                <p>A kiszállás NINCS benne az árban.</p>
              </React.Fragment>
            }
            time={ '45 perc+ ' }
            price={ '10e-15e Ft' }
          />
          <ServiceItem
            image={ 'images/services/muszempilla.jpg' }
            title={ 'Műszempilla felhelyezés' }
            lead={ 'Minőségi, természetes' }
            description={
              <React.Fragment>
                <p>Kizárólag minőségi, természetes, kényelmes szempillákat használok.</p>
                <p>A pilla dobozát minden esetben odaadom, így többször is fel lehet használni őket.</p>
              </React.Fragment>
            }
            time={ '15 perc ' }
            price={ '5 000 Ft' }
          />
          <ServiceItem
            image={ 'images/services/eskuvo.jpg' }
            title={ 'Esküvői smink csomag' }
            lead={ 'Konzultáció, próbasmink' }
            description={
              <React.Fragment>
                <p>Mit tartalmaz?</p>
                <ul>
                  <li>Részletes konzultációt</li>
                  <li>Próbasminket, ami garanciális</li>
                  <li>Budapest területén kiszállást</li>
                  <li>Sminked az esküvő napján</li>
                  <li>Vőlegény arcának lemattítását</li>
                </ul>
                <p>További feláras szolgáltatások:</p>
                <ul>
                  <li>Vendégek sminkje - 7e Ft/fő</li>
                  <li>Műszempilla felhelyezés - 3-7e Ft/fő</li>
                  <li>Smink kreatív fotózásra - 10e Ft</li>
                  <li>Készenléti felár fotózásra - 2e Ft/óra</li>
                  <li>Vidéki kiszállás - egyéni megállapodás alapján</li>
                </ul>
                <p>A szolgáltatás időtartama a létszámtól és a megrendelt szolgáltatásoktól
                   függ.</p>
                <p>További részletekért keress privát üzenetben.</p>
              </React.Fragment>
            }
            time={ '1,5-3 óra ' }
            price={ '35 000 Ft' }
          />
          <ServiceItem
            image={ 'images/services/ajandekutalvany.jpg' }
            title={ 'Ajándékutalvány' }
            lead={ 'Ajándékozz élményt' }
            description={
              <React.Fragment>
                <p>Minden szolgáltatás megvásárolható ízléses, névre szóló ajándékutalvány
                   formájában.</p>
                <p>A legjobb döntés élményt ajándékozni valakinek, nem egy újabb porfogót a
                   polcra.</p>
              </React.Fragment>
            }
            time={ '' }
            price={ '' }
          />
        </div>
      </div>
    );
  }
}

export default Services;
