import PropTypes from 'prop-types';
import React, { Component } from 'react';
import BlogPage from '../pages/BlogPage';
import { Route } from 'react-router-dom';

import BlogList from '../components/BlogList';

class Blog extends Component {

  render() {
    const match = this.props.match;

    return (
      <React.Fragment>
        <Route path={ `${match.path}/:slug` } component={ BlogPage } />
        <Route
          exact
          path={ match.path }
          component={ BlogList }
        />
      </React.Fragment>
    );
  }
}

Blog.propTypes = {
  match: PropTypes.object,
};

export default Blog;
