import { POSITION, FONTS, COLORS } from '../styles';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  header: {
    position: POSITION.FIXED,
    width: '100%',
    height: 80,
    top: 0,
    left: 0,
    zIndex: 999,
    ...FONTS.OPEN_SANS,

    ':after': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: '100%',
      height: 80,
      top: '-2px',
      left: 0,
      zIndex: 1,
      transform: 'rotateZ(0deg)',
      transformOrigin: '100% 0 0',
      background: `linear-gradient(to bottom left, ${ COLORS.PINK } 50%, ${ COLORS.TRANSPARENT } 51%)`,

      '@media (max-width: 1000px)': {
        width: '100%',
        left: 0,
      },

      '@media (max-width: 768px)': {
        display: 'none',
      },

    },

  },

  headerActive: {

    '@media (max-width: 768px)': {
      height: '100vh',
      overflow: 'hidden',
      background: 'rgba(255, 41, 132, .95)',
    },

  },

  header__nav: {
    position: POSITION.RELATIVE,
    display: 'flex',
    width: '100%',
    maxWidth: 1200,
    height: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    padding: '0',
    margin: '0 auto',
    zIndex: 10,
    color: COLORS.WHITE,
    userSelect: 'none',
    boxSizing: 'border-box',

    '@media (max-width: 1200px)': {
      padding: '0 20px',
    },

    '@media (max-width: 768px)': {
      display: 'none',
      height: 'auto',
      padding: '40px 20px',
    },

  },

  headerNavActive: {

    '@media (max-width: 768px)': {
      display: 'block',
    },

  },

  header__mobilenav: {
    position: POSITION.ABSOLUTE,
    display: 'none',
    width: 40,
    height: 40,
    top: 20,
    right: 20,
    overflow: 'hidden',
    zIndex: 100,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    textIndent: '110%',
    background: COLORS.PINK,

    ':after': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: 20,
      height: 2,
      top: 15,
      left: 10,
      background: COLORS.WHITE,
    },

    ':before': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: 20,
      height: 2,
      bottom: 15,
      left: 10,
      background: COLORS.WHITE,
    },

    '@media (max-width: 768px)': {
      display: 'block',
    },

  },

  header__link: {
    position: POSITION.RELATIVE,
    display: 'flex',
    height: 40,
    alignItems: 'flex-start',
    padding: '20px 10px 0',
    fontSize: '12px',
    lineHeight: 1,
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: COLORS.WHITE,
    boxSizing: 'border-box',

    ':hover': {
      color: COLORS.PINK_DARKER,
      transition: 'color .3s ease-out',
    },

    ':last-child': {
      paddingRight: 0,
    },

    ':nth-child(n) > span': {
      display: 'none',
    },

    '@media (max-width: 768px)': {
      display: 'block',

      ':nth-child(n) > span': {
        display: 'block',
      },

    },

  },

  header__active_link: {
    textDecoration: 'underline',
    color: COLORS.WHITE,
  },

  logoWrapper: {
    width: 24,
    height: 24,
    zIndex: 10,
    color: COLORS.WHITE,

    '@media (max-width: 768px)': {
      display: 'none',
    },

  },

  logo: {
    width: '100%',
    height: '100%',
    top: -8,
  },
});
