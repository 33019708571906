import { StyleSheet } from 'aphrodite/no-important';
import { POSITION, FONTS, COLORS, DISPLAY } from '../styles';

export default StyleSheet.create({

  blogPageWrapper: {
    position: POSITION.RELATIVE,
    ...FONTS.OPEN_SANS,
    color: COLORS.GRAY,
  },

  blogPageWrapperInner: {
    position: POSITION.RELATIVE,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: 768,
    margin: '0 auto',
    padding: '40px 40px',
    ...FONTS.OPEN_SANS,
    background: COLORS.WHITE,
  },

  blogContentWrapper: {},

  blogParagraph: {
    padding: 0,
    margin: '0 0 20px',
    fontSize: 16,
    lineHeight: 1.5,
    color: COLORS.GRAY5,

    ':nth-child(1n) > b': {
      fontWeight: 700,
    },

    ':nth-child(1n) > a': {
      textDecoration: 'underline',
      color: COLORS.PINK,
    },

    '@media (max-width: 768px)': {
      fontSize: 14,
    },

    '@media (max-width: 640px)': {
      fontSize: 13,
    },

  },

  blogImageWrapper: {
    position: POSITION.RELATIVE,
    padding: '20px 0 0',
    margin: '0 0 20px',

    '@media (max-width: 768px)': {
      padding: '10px 0 0',
      margin: '0 0 20px',
    },

    '@media (max-width: 640px)': {
      padding: '10px 0 0',
      margin: '0 0 10px',
    },

  },

  blogImage: {
    display: DISPLAY.BLOCK,
    width: '100%',
    height: 'auto',
    margin: '0 auto',
  },

  blogImageLimitHeight: {
    width: 'auto',
    maxHeight: '50vh',
  },

  blogImageCaption: {
    padding: '10px 0',
    fontSize: 14,
    fontStyle: 'italic',
    lineHeight: 1.5,
    textAlign: 'center',
    color: COLORS.GRAY5,

    ':nth-child(1n) > b': {
      fontWeight: 700,
    },

    '@media (max-width: 768px)': {
      fontSize: 14,
    },

    '@media (max-width: 640px)': {
      fontSize: 13,
    },
  },

  blogUnorderedList: {
    position: POSITION.RELATIVE,
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },

  blogUnorderedListItem: {
    position: POSITION.RELATIVE,
    padding: '0 0 0 40px',
    margin: '0 0 20px',
    fontSize: 16,
    lineHeight: 1.5,
    color: COLORS.GRAY5,

    ':before': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: 6,
      height: 6,
      top: 10,
      left: 20,
      background: COLORS.PINK,
    },

    ':nth-child(1n) > b': {
      fontWeight: 700,
    },

    ':nth-child(1n) > a': {
      textDecoration: 'underline',
      color: COLORS.PINK,
    },

    '@media (max-width: 768px)': {
      padding: '0 0 0 30px',
      fontSize: 14,

      ':before': {
        top: 8,
        left: 10,
      },

    },

    '@media (max-width: 640px)': {
      padding: '0 0 0 20px',
      fontSize: 13,

      ':before': {
        top: 5,
        left: 5,
      },

    },
  },

  blogSubTitle: {
    padding: '10px 0 0',
    margin: '0 0 20px',
    fontSize: 22,
    fontWeight: 600,
    lineHeight: 1.4,
    color: COLORS.BLACK,

    ':nth-child(1n) > b': {
      fontWeight: 700,
    },

    '@media (max-width: 768px)': {
      fontSize: 18,
    },

    '@media (max-width: 640px)': {
      fontSize: 15,
    },

  },

  blogSubTitleH3: {},

  blogSubTitleH4: {
    fontSize: 19,
    margin: '0 0 15px',

    '@media (max-width: 768px)': {
      fontSize: 17,
    },

    '@media (max-width: 640px)': {
      fontSize: 14,
    },

  },

  blogSubTitleH5: {
    fontSize: 17,
    margin: '0 0 10px',

    '@media (max-width: 768px)': {
      fontSize: 15,
    },

    '@media (max-width: 640px)': {
      fontSize: 14,
    },

  },

  blogSubTitleH6: {
    margin: '0 0 10px',
    fontSize: 15,
  },

});
