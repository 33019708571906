import { css } from 'aphrodite';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import s from './WorkPage.style';
import { COLORS, FONTS } from '../styles';

const WORKDATA = `${ process.env.PUBLIC_URL }/data/works/`;

class WorkPage extends Component {
  state = {
    isLoading: true,
    data: [],
    slug: '',
    error: false,
    currentImage: 0,
    viewerIsOpen: false,
  };

  componentDidMount() {
    const { slug } = this.props.match.params;
    this.fetchData(slug);
  }

  fetchData(slug) {
    fetch(`${ WORKDATA }${ slug }.json`)
      .then(response => response.json())
      .then(data => this.setState(
        {
          data,
          slug,
          isLoading: false,
        }),
      )
      .catch(error => this.setState({ error, isLoading: false }));
  }

  openLightBox(event, { index }) {
    this.setState({
      currentImage: index,
      viewerIsOpen: true,
    });
  }

  closeLightBox() {
    this.setState({
      currentImage: 0,
      viewerIsOpen: false,
    });
  }

  render() {
    const { isLoading, error, data, viewerIsOpen, currentImage, slug } = this.state;

    return (
      <section>
        { !isLoading && !error && (
          <React.Fragment>
            <div className={ css(s.workContentWrapper) }>
              <Gallery
                photos={ data.images }
                targetRowHeight={ slug === 'tanuloim-munkai' ? 500 : 400 }
                onClick={ this.openLightBox.bind(this) }
              />
              <ModalGateway>
                { viewerIsOpen ? (
                  <Modal onClose={ this.closeLightBox.bind(this) }>
                    <Carousel
                      currentIndex={ currentImage }
                      views={ data.images.map(x => ({
                        ...x,
                        caption: '',
                      })) }
                      styles={ {
                        container: base => ({
                          ...base,
                          ...FONTS.OPEN_SANS,
                          color: COLORS.WHITE,
                        }),
                        footer: base => ({
                          ...base,
                          ...FONTS.OPEN_SANS,
                          color: COLORS.WHITE,
                        }),
                      } }
                    />
                  </Modal>
                ) : null }
              </ModalGateway>
            </div>
          </React.Fragment>
        ) }
      </section>
    );
  }
}

WorkPage.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};

export default WorkPage;
