import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Page from './components/Page';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Blog from './pages/Blog';
import Works from './pages/Works';

const SPRITE_ID = 'svg-sprite';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.fetchSprite();
  }

  componentWillUnmount() {
    this.cleanupSprite();
  }

  fetchSprite = async () => {
    const text = await fetch(`${ process.env.PUBLIC_URL }/spritemap.svg`).then(data => data.text());
    const div = document.createElement('div');
    div.id = SPRITE_ID;
    div.style.display = 'none';
    div.innerHTML = text;
    document.body.appendChild(div);
  };

  cleanupSprite = () => {
    const sprite = document.querySelector(`#${ SPRITE_ID }`);
    if (sprite) {
      document.body.removeChild(sprite);
    }
  };

  render() {
    return (
      <Router>
        <Switch>
          <Page>
            <Route exact path="/" component={ Home } />
            <Route path="/rolam" component={ About } />
            <Route path="/szolgaltatasok" component={ Services } />
            <Route path="/blog" component={ Blog } />
            <Route path="/munkak" component={ Works } />
          </Page>
        </Switch>
      </Router>
    );
  }
}

export default App;
