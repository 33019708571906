import { POSITION, COLORS } from '../styles';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({

  heroTitle: {
    position: POSITION.RELATIVE,
    width: '100%',
    maxWidth: 800,
    margin: '0 auto',
    fontSize: 36,
    fontWeight: 700,
    padding: '120px 40px',
    color: COLORS.WHITE,
    zIndex: 10,
    textAlign: 'center',
    textTransform: 'uppercase',

    '@media (max-width: 768px)': {
      padding: '80px 40px',
      fontSize: 28,
    },

    '@media (max-width: 640px)': {
      padding: '40px 20px',
      fontSize: 20,
    },

  },

  heroTitleWithBackground: {

    ':after': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: '100vw',
      height: '100%',
      top: 0,
      left: '50%',
      opacity: 1,
      zIndex: -1,
      background: `linear-gradient(to bottom, ${COLORS.TRANSPARENT} 0%, ${COLORS.BLACK40} 40%, ${COLORS.BLACK40} 60%, ${COLORS.TRANSPARENT} 100%)`,
      transform: 'translateX(-50%)',
    },

  },

});
