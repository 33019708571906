import { POSITION, COLORS, FONTS } from '../styles';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({

  reviewsWrapper: {
    position: POSITION.RELATIVE,
    width: '100%',
    height: 'auto',
    padding: '40px 0 0',
    textAlign: 'center',

    ':after': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: '100%',
      height: 80,
      top: 0,
      left: 0,
      zIndex: 101,
      pointerEvents: 'none',
      transform: 'rotateZ(0deg)',
      transformOrigin: '100% 0 0',
      background: `linear-gradient(to bottom right, ${COLORS.WHITE} 50%, ${COLORS.TRANSPARENT} 51%)`,

      '@media (max-width: 1000px)': {
        width: '100%',
        left: 0,
      },

      '@media (max-width: 768px)': {
        display: 'none',
      },

    },

    '@media (max-width: 768px)': {
      padding: '40px 0 0',
    },

  },

  reviewsWrapperInner: {
    position: POSITION.RELATIVE,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    maxWidth: 1200,
    margin: '0 auto',
    padding: '80px 10px 0',
    ...FONTS.OPEN_SANS,
    textAlign: 'left',

    '@media (max-width: 640px)': {
      padding: '40px 10px 0',
    },

  },

  reviewsTitle: {
    position: POSITION.RELATIVE,
    display: 'inline-block',
    ...FONTS.OPEN_SANS,
    fontSize: 24,
    fontWeight: 500,
    padding: '0 0 10px',
    marginBottom: 10,
    zIndex: 102,
    textAlign: 'center',
    color: COLORS.GRAY2,

    ':after': {
      position: POSITION.ABSOLUTE,
      content: '""',
      height: 1,
      bottom: 0,
      left: 0,
      right: 0,
      background: COLORS.PINK,
    },

    '@media (max-width: 640px)': {
      fontSize: 20,
    },

  },

});
