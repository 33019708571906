import { StyleSheet } from 'aphrodite/no-important';
import { POSITION, COLORS, FONTS } from '../styles';

export default StyleSheet.create({

  contentWrapper: {
    position: POSITION.RELATIVE,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    ...FONTS.OPEN_SANS,
    background: COLORS.WHITE,
  },

  contentWrapperInner: {
    position: POSITION.RELATIVE,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: 768,
    margin: '0 auto',
    padding: '40px 40px',
    ...FONTS.OPEN_SANS,
    background: COLORS.WHITE,
  },

  contentParagraph: {
    padding: 0,
    margin: '0 0 20px',
    fontSize: 16,
    lineHeight: 1.5,
    color: COLORS.GRAY5,

    ':nth-child(1n) > b': {
      fontWeight: 700,
    },

    '@media (max-width: 768px)': {
      fontSize: 14,
    },

    '@media (max-width: 640px)': {
      fontSize: 13,
    },

  },

});
