import { POSITION, FONTS, COLORS } from '../styles';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  footer: {
    position: POSITION.FIXED,
    width: '100%',
    height: 100,
    bottom: 0,
    left: 0,
    zIndex: -1,
    ...FONTS.OPEN_SANS,
    background: COLORS.WHITE,

    '@media (max-width: 768px)': {
      position: POSITION.RELATIVE,
      height: 'auto',
      zIndex: 1,
    },

  },

  footer__nav: {
    position: POSITION.RELATIVE,
    display: 'flex',
    width: '100%',
    maxWidth: 1200,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '0',
    margin: '0 auto',
    zIndex: 10,
    color: COLORS.WHITE,
    boxSizing: 'border-box',

    '@media (max-width: 1200px)': {
      padding: '0 20px',
    },

    '@media (max-width: 640px)': {
      flexWrap: 'wrap',
      flex: '0 0 100%',
      flexDirection: 'column-reverse',
    },

  },

  logoWrapper: {
    position: POSITION.RELATIVE,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    width: 'auto',
    height: 72,
    padding: 0,
    fontSize: 12,
    lineHeight: '20px',
    zIndex: 10,
    color: COLORS.GRAY,
    background: COLORS.WHITE,

    '@media (max-width: 640px)': {
      width: '100%',
      fontSize: 11,
      lineHeight: '16px',
    },

  },

  logo: {
    position: POSITION.RELATIVE,
    width: '72px',
    height: '72px',
    padding: '0 18px 0 0',
    color: COLORS.TRANSPARENT,
  },

  socialWrapper: {
    position: POSITION.RELATIVE,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    width: 'auto',
    height: 72,
    padding: 0,
    zIndex: 10,
    color: COLORS.GRAY,
    background: COLORS.WHITE,

    '@media (max-width: 640px)': {
      width: '100%',
      justifyContent: 'space-between',
      padding: '0 10px',
    },

  },

  socialLogo: {
    position: POSITION.RELATIVE,
    width: '24px',
    height: '24px',
    margin: '0 0 0 12px',

    '@media (max-width: 640px)': {
      margin: 0,
    },

  },

  socialLink: {
    position: POSITION.RELATIVE,
    cursor: 'pointer',
    color: COLORS.GRAY,
    boxSizing: 'border-box',
    transition: 'color .3s linear',

    ':hover': {
      color: COLORS.PINK,
    },

  },

  footerLink: {
    cursor: 'pointer',
    color: COLORS.GRAY,
    textDecoration: 'none',

    ':hover': {
      color: COLORS.PINK,
    },

  },

});
