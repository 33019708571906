import slugifyFn from 'slugify';

import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const navigateTo = (path, props) => history.push(path, props);

export const slugify = str => slugifyFn(str).toLowerCase();

export const delay = (ms = 500) => new Promise(resolve => setTimeout(resolve, ms));

export const mergeStyle = (...styles) => styles.filter(Boolean).join(' ');
