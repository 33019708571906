import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import HeroTitle from '../components/HeroTitle';
import s from './HeroImage.style';

class HeroImage extends Component {

  static renderTags(tags) {
    return (
      <div className={ css(s.heroImageTags) }>
        { tags.map((tag, id) => {
          return (
            <div key={ `t-${id}` }>{ tag }</div>
          );
        }) }
      </div>
    );
  }

  static formatDate(date) {
    const rawDate = date.split('.') || [];
    const year = rawDate[0];
    const month = rawDate[1];
    const day = rawDate[2];

    return `${year}. ${HeroImage.convertMonths(month)} ${day}.`;
  }

  static convertMonths(month) {
    let monthName;

    switch (month) {
      case '01':
        monthName = 'január';
        break;
      case '02':
        monthName = 'február';
        break;
      case '03':
        monthName = 'március';
        break;
      case '04':
        monthName = 'április';
        break;
      case '05':
        monthName = 'május';
        break;
      case '06':
        monthName = 'június';
        break;
      case '07':
        monthName = 'július';
        break;
      case '08':
        monthName = 'augusztus';
        break;
      case '09':
        monthName = 'szeptember';
        break;
      case '10':
        monthName = 'október';
        break;
      case '11':
        monthName = 'november';
        break;
      case '12':
        monthName = 'december';
        break;
    }

    return monthName;
  }

  render() {
    return (
      <div className={ css([s.heroWrapper, this.props.smallVersion ? s.heroWrapperSmall : '']) }>
        <HeroTitle title={ this.props.title } titleBackground={ this.props.titleBackground } />
        { this.props.image.length < 2 && (
          <img
            className={ css(s.heroImage) }
            src={ process.env.PUBLIC_URL + this.props.image[0] }
          />
        ) }
        { this.props.published && (
          <div className={ css(s.heroImageDetails) }>
            <time
              className={ css(s.heroImagePublished) }>{ HeroImage.formatDate(this.props.published) }</time>
            { HeroImage.renderTags(this.props.tags) }
          </div>
        ) }
      </div>
    );
  }
}

HeroImage.propTypes = {
  title: PropTypes.string,
  image: PropTypes.array,
  tags: PropTypes.array,
  published: PropTypes.string,
  isBlogPage: PropTypes.bool,
  smallVersion: PropTypes.bool,
  titleBackground: PropTypes.bool,
};

export default HeroImage;
