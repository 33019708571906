import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import s from './ReviewItem.style';

class ReviewItem extends Component {
  state = {
    hover: false,
  };

  onMouseOut = () => {
    this.setState({ hover: false });
  };

  onMouseOver = () => {
    this.setState({ hover: true });
  };

  render() {
    return (
      <div
        className={
          css(
            s.reviewItem,
            this.state.hover ? s.reviewItemHover : '',
          )
        }
        onMouseOver={ this.onMouseOver }
        onMouseOut={ this.onMouseOut }
      >
        <div className={ css(s.reviewPhoto) }>
          <img
            src={ process.env.PUBLIC_URL + this.props.image }
            className={
              css(
                s.reviewPhotoImg,
                this.state.hover ? s.reviewPhotoImgHover : '',
              )
            }
            alt={ this.props.title } />
        </div>
        <h3 className={ css(s.reviewTitle) }>{ this.props.title }</h3>
        <p className={ css(s.reviewText) }>{ this.props.text }</p>
      </div>
    );
  }
}

ReviewItem.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};

export default ReviewItem;
