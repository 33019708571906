import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import WorkPage from './WorkPage';
import WorksList from './WorksList';

class Works extends Component {
  state = {};

  render() {
    const match = this.props.match;

    return (
      <React.Fragment>
        <Route path={ `${ match.path }/:slug` } component={ WorkPage } />
        <Route
          exact
          path={ match.path }
          component={ WorksList }
        />
      </React.Fragment>
    );
  }
}

Works.propTypes = {
  match: PropTypes.object,
};

export default Works;
