import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import s from './HeroTitle.style';

class HeroTitle extends Component {
  render() {
    return (
      <h2 className={
        css(
          s.heroTitle,
          this.props.titleBackground ? s.heroTitleWithBackground : '',
        )
      }>{ this.props.title }</h2>
    );
  }
}

HeroTitle.propTypes = {
  title: PropTypes.string,
  titleBackground: PropTypes.bool,
};


export default HeroTitle;
