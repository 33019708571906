import 'clientjs';

const client = new window.ClientJS();

export const IS_MOBILE = client.isMobile();

export const IS_DEV = process.env.NODE_ENV === 'development';

export const BREAKPOINTS = {
  MOBILE: 768,
};

export const ORIENTATION = {
  LANDSCAPE: 'LANDSCAPE',
  PORTRAIT: 'PORTRAIT',
};
