import { POSITION, COLORS } from '../styles';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({

  reviewItem: {
    position: POSITION.RELATIVE,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: '1 0 25%',
    padding: '20px 10px',
    margin: '0 0 20px',

    ':after': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: 118,
      height: 118,
      top: 20,
      left: '50%',
      zIndex: 1,
      background: COLORS.PINK_DARK,
      transform: 'translateX(-50%) rotateZ(35deg)',
      transition: 'transform .3s ease-out',
    },

    '@media (max-width: 900px)': {
      flex: '0 1 50%',
    },

    '@media (max-width: 640px)': {
      flex: '0 1 100%',
    },

  },

  reviewItemHover: {

    ':after': {
      transform: 'translateX(-50%) rotateZ(30deg)',
    },

  },

  reviewPhoto: {
    position: POSITION.RELATIVE,
    display: 'block',
    width: 120,
    height: 120,
    margin: '0 auto 30px',
    overflow: 'hidden',
    zIndex: 2,
    border: `4px solid ${COLORS.PINK}`,
    transform: 'rotateZ(45deg)',
  },

  reviewPhotoImg: {
    position: POSITION.ABSOLUTE,
    display: 'block',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textIndent: '110%',
    objectFit: 'cover',
    transform: 'rotateZ(-45deg) translate(0, 0) scale(1.45)',
    transition: 'transform .3s ease-out',

  },

  reviewPhotoImgHover: {
    transform: 'rotateZ(-50deg) translate(0, 0) scale(1.5)',
  },

  reviewTitle: {
    position: POSITION.RELATIVE,
    padding: '20px 0 20px',
    margin: '0 0 20px',
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: COLORS.PINK,

    ':after': {
      position: POSITION.ABSOLUTE,
      content: '""',
      height: 1,
      bottom: 0,
      left: 10,
      right: 10,
      zIndex: 1,
      background: COLORS.GRAY3,
    },

  },

  reviewText: {
    padding: '0 10px',
    fontSize: 14,
    lineHeight: 1.4,
    textAlign: 'left',
    color: COLORS.GRAY2,
  },

});
