import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import { NavLink } from 'react-router-dom';
import Icon from './Icon';
import s from './Header.style';

/* eslint-disable */
class Header extends Component {
  state = {
    isMobileNavActive: false,
  };

  componentDidMount() {
    this.mobileNavTrigger = React.createRef();
  }

  toggleMobileNav() {
    this.setState({
      ...this.state,
      isMobileNavActive: !this.state.isMobileNavActive,
    });
  }

  hideMobileNav() {
    this.setState({
      ...this.state,
      isMobileNavActive: false,
    });
  }

  render() {
    return (
      <header className={ css([s.header, this.state.isMobileNavActive ? s.headerActive : '']) }>
        <a
          onClick={ this.toggleMobileNav.bind(this) }
          className={ css(s.header__mobilenav) }
          ref={ this.mobileNavTrigger }>Mobil menü</a>
        <nav
          className={ css([s.header__nav, this.state.isMobileNavActive ? s.headerNavActive : '']) }>
          <NavLink
            onClick={ this.hideMobileNav.bind(this) }
            className={ css(s.header__link) }
            activeClassName={ css(s.header__active_link) }
            exact to="/">
            <div className={ css(s.logoWrapper) }>
              <Icon name="home" className={ css(s.logo) } />
            </div>
            <span>Nyitólap</span>
          </NavLink>
          <NavLink
            onClick={ this.hideMobileNav.bind(this) }
            className={ css(s.header__link) }
            activeClassName={ css(s.header__active_link) }
            exact to="/rolam">Rólam</NavLink>
          <NavLink
            onClick={ this.hideMobileNav.bind(this) }
            className={ css(s.header__link) }
            activeClassName={ css(s.header__active_link) }
            exact
            to="/szolgaltatasok">Szolgáltatásaim</NavLink>
          <NavLink
            onClick={ this.hideMobileNav.bind(this) }
            className={ css(s.header__link) }
            activeClassName={ css(s.header__active_link) }
            to="/blog">Blog</NavLink>
          <NavLink
            onClick={ this.hideMobileNav.bind(this) }
            className={ css(s.header__link) }
            activeClassName={ css(s.header__active_link) }
            exact
            to="/munkak">Munkák</NavLink>
        </nav>
      </header>
    );
  }
}

export default Header;
