import { css } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import HeroImage from '../components/HeroImage';
import GalleryCarousel from '../components/GalleryCarousel';
import s from './BlogPage.style';

const BLOGDATA = `${ process.env.PUBLIC_URL }/data/`;

class BlogPage extends Component {
  state = {
    isLoading: true,
    data: [],
    slug: '',
    error: false,
  };

  componentDidMount() {
    const { slug } = this.props.match.params;
    this.fetchData(slug);
  }

  fetchData(slug) {
    fetch(`${ BLOGDATA }${ slug }.json`)
      .then(response => response.json())
      .then(data => this.setState(
        {
          data,
          slug,
          isLoading: false,
        }),
      )
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const { isLoading, error, data } = this.state;
    const { title, hero, published, tags, titleBackground, smallVersion } = data;

    return (
      <section className={ css(s.blogPageWrapper) }>
        { !isLoading && !error && (
          <React.Fragment>
            <HeroImage
              title={ title }
              titleBackground={ titleBackground || false }
              smallVersion={ smallVersion || false}
              image={ hero }
              published={ published }
              tags={ tags }
              isBlogPage={ true }
            />
          </React.Fragment>
        )
        }
        <section className={ css(s.blogPageWrapperInner) }>
          {
            !isLoading && !error ? BlogContent(data) : ''
          }
        </section>
      </section>
    );
  }
}

const getUnorderedList = (content, id) => {
  return <ul key={ `ul-${ id }` } className={ css(s.blogUnorderedList) }>
    { content.map((item, i) => {
      return (
        <li
          className={ css(s.blogUnorderedListItem) }
          key={ `con-${ id }-${ i }` }
          dangerouslySetInnerHTML={ { __html: item } } />
      );
    }) }
  </ul>;
};

const getParagraph = (content, id) => {
  if (Array.isArray(content)) {
    return (
      content.map((item, i) => {
        return (
          <p
            className={ css(s.blogParagraph) } key={ `con-${ i }` }
            dangerouslySetInnerHTML={ { __html: item } } />
        );
      }));
  } else {
    return (
      <p
        className={ css(s.blogParagraph) } key={ `con-${ id }` }
        dangerouslySetInnerHTML={ { __html: content } } />
    );
  }
};

const getImage = (content, id) => (
  <figure className={ css(s.blogImageWrapper) } key={ `img-${ id }` }>
    <img
      className={ css([s.blogImage, content.limitHeight ? s.blogImageLimitHeight : '']) }
      src={ process.env.PUBLIC_URL + content.img }
    />
    <figcaption className={ css(s.blogImageCaption) }>{ content.caption }</figcaption>
  </figure>);

const getSubTitle = (type, content, id) => {
  const props = {
    className: css([s.blogSubTitle, s[`blogSubTitle${ type.toUpperCase() }`]]),
    key: `sub-${ id }`,
  };
  return React.createElement(
    type, props, content,
  );
};

const renderContent = ({ type, content }, id) => {
  let html;

  switch (type) {
    case 'paragraph':
      html = getParagraph(content, id);
      break;
    case 'h3':
    case 'h4':
    case 'h5':
    case 'h6':
      html = getSubTitle(type, content, id);
      break;
    case 'image':
      html = getImage(content, id);
      break;
    case 'gallery':
      html = <GalleryCarousel key={ `gallery-carousel${ id }` } images={ content } />;
      break;
    case 'ul':
      html = getUnorderedList(content, id);
      break;
    default:
      html = getParagraph(content, id);
      break;
  }

  return html;
};

const BlogContent = ({ body }) => {
  return (
    <React.Fragment>
      <div className={ css(s.blogContentWrapper) }>
        { body.map((content, id) => {
          return (
            renderContent(content, id)
          );
        }) }
      </div>
    </React.Fragment>
  );
};

BlogPage.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};

BlogContent.propTypes = {
  title: PropTypes.string,
  titleBackground: PropTypes.bool,
  hero: PropTypes.array,
  tags: PropTypes.array,
  published: PropTypes.string,
  body: PropTypes.object,
};

export default BlogPage;
