import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import BlogItem from './BlogItem';
import s from './BlogList.style';
import HeroImage from './HeroImage';

class BlogList extends Component {
  state = {};

  render() {
    return (
      <div>
        <HeroImage
          title={ 'Írásaim sminkekről, termékekről, magamról?' }
          image={ [process.env.PUBLIC_URL + '/images/bloglist-hero.jpg'] }
        />
        <div className={ css(s.blogListWrapper) }>
          <div className={ css(s.blogListWrapperInner) }>

            <BlogItem
              image={ '/images/blog/veszelyes-feketepiac.jpg' }
              tags={ 'smink, jeffree star' }
              title={ 'Veszélyes sminktermékek' }
              time={ '2019. április 4.' }
              lead={ 'Híres vagyok arról, hogy mindig “I\'m on a mission” üzemmódban vagyok, mindig van valami történés a világban, ami ellen/mellett harcolok/kampányolok/tűntetek/stb.' }
              url={ 'veszelyes-feketepiac' }
            />

            <BlogItem
              image={ '/images/blog/3-smink-1-paletta-jeffree-star-androgyny.jpg' }
              tags={ 'smink, jeffree star, paletta' }
              title={ '3 smink 1 paletta: Jeffree Star – Androgyny' }
              time={ '2019. március 5.' }
              lead={ 'Ez van, Jeffree Star lázban égek tavaly ősz óta... Mivel elég finnyás vagyok a sminkcuccokat illetően, ezért ez nagy szó, hogy ennyire imádom szinte az összes termékét.' }
              url={ '3-smink-1-paletta-jeffree-star-androgyny' }
            />

            <BlogItem
              image={ '/images/blog/amirol-nem-beszel-soha-senki-ebben-a-szakmaban.jpg' }
              tags={ 'smink, story time, szakma' }
              title={ 'Amiről nem beszél soha senki ebben a szakmában...' }
              time={ '2019. február 4.' }
              lead={ 'Sokszor jut eszembe, hogy feladom, és ugyanannyiszor jut eszembe, hogy soha nem adom fel.' }
              url={ 'amirol-nem-beszel-soha-senki-ebben-a-szakmaban' }
            />

            <BlogItem
              image={ '/images/blog/story-time-hogyan-emlekezem-2018ra.jpg' }
              tags={ 'smink, story time' }
              title={ 'Story time: Hogyan emlékezem 2018-ra' }
              time={ '2019. január 6.' }
              lead={ 'Sokat gondolkodtam, hogy kéne valami összegzésféleséget írni 2018-ról, mert elég kacifántos év volt… De, ha jól láttam, akkor más is...' }
              url={ 'story-time-hogyan-emlekezem-2018-ra' }
            />

            <BlogItem
              image={ '/images/blog/karacsonyi-ajandekotletek.jpg' }
              tags={ 'smink, xmas' }
              title={ 'Karácsonyi ajándékötletek' }
              time={ '2018. december 4.' }
              lead={ 'Jönnek az ünnepek és én legtöbbször totál tanácstalan vagyok, hogy kinek mit ajándékozzak. Pedig év közben ezer jó ötletem van...' }
              url={ 'karacsonyi-ajandekotletek' }
            />

            <BlogItem
              image={ '/images/blog/3-smink-1-paletta-smashbox.jpg' }
              tags={ 'smink, teszt, smashbox' }
              title={ '3 smink 1 paletta: Smashbox - Petal Metal' }
              time={ '2018. november 22.' }
              lead={ 'Nagyon érett már bennem egy bővebb bejegyzés a Smashbox márka kapcsán.' }
              url={ '3-smink-1-paletta-smashbox' }
            />

            <BlogItem
              image={ '/images/blog/3-smink-1-paletta-jeffree-star.jpg' }
              tags={ 'smink, teszt, jeffree star' }
              title={ '3 smink 1 paletta: Jeffree Star - Blood Sugar' }
              time={ '2018. november 10.' }
              lead={ 'Ezekről a terméktesztekről mindig az jut eszembe, amikor az \'Így jártam anyátokkal\'-ban azt mondja Robin, hogy Kanadába a \'90-es évek 2000-ben érkeztek meg. 😀' }
              url={ '3-smink-1-paletta-jeffree-star-blood-sugar' }
            />

            <BlogItem
              image={ '/images/blog/3-smink-1-paletta-jeffree-star-thirsty.jpg' }
              tags={ 'smink, teszt, jeffree star' }
              title={ '3 smink 1 paletta: Jeffree Star - Thirsty' }
              time={ '2018. október 30.' }
              lead={ 'Annyi szemhéjpúder palettát halmoztam fel, hogy már klinikai eset vagyok. Szeretnék egy olyan sorozatot indítani, ahol három különböző sminket készítek...' }
              url={ '3-smink-1-paletta-jeffree-star-thirsty' }
            />

            <BlogItem
              image={ '/images/blog/kenceficek-amik-mukodnek.jpg' }
              tags={ 'beauty, bioderma, tippek' }
              title={ 'Kenceficék, amik tényleg hatásosak' }
              time={ '2018. október 3.' }
              lead={ 'Ha már Téged is az agyvérzés kerülget a mindent ígérő, de semmit sem produkáló szépségápolási termékektől, akkor ez Neked szól!' }
              url={ 'kenceficek-amik-tenyleg-hatasosak' }
            />

            <BlogItem
              image={ '/images/blog/story-time-hogyan-lettem-sminkes.jpg' }
              tags={ 'smink, story time' }
              title={ 'Story time: Hogyan lettem sminkes?' }
              time={ '2018. szeptember 19.' }
              lead={ 'Szerettem volna kicsit mesélni, hogy miért és hogyan lettem sminkes. Sokan kérdezitek, hogy mégis hogy jött nekem ez a sminkelés.' }
              url={ 'story-time-hogyan-lettem-sminkes' }
            />

            <BlogItem
              image={ '/images/blog/bioderma-hydrabio-test.jpg' }
              tags={ 'teszt, bioderma' }
              title={ 'Letesztelve: BIODERMA Hydrabio mélyhidratáló krém és Sensibio arctisztító' }
              time={ '2018. július 18.' }
              lead={ 'Akiknek fontos a fényvédelem, érzékeny a bőrük, olvassák el ezt bejegyzést, mert nagyon hasznos információkat osztok meg Veletek!' }
              url={ 'letesztelve-bioderma-hydrabio-melyhidratalo-krem-es-sensibio-arctisztito' }
            />

            <BlogItem
              image={ '/images/blog/agi-kedvenc-vloggerei.jpg' }
              tags={ 'youtube, smink, vlog' }
              title={ 'Kedvenc Youtube beauty vloggereim' }
              time={ '2018. május 29.' }
              lead={ 'Végre összegyűjtöttem kedvenc beauty vloggereimet.' }
              url={ 'agi-kedvenc-youtube-beauty-vloggerei' }
            />

            <BlogItem
              image={ '/images/blog/mi-van-agi-nesszeszereben.jpg' }
              tags={ 'smink, nesszeszer, mac' }
              title={ 'Mi van a neszesszeremben?' }
              time={ '2018. február 18.' }
              lead={ 'Nem is értem miért állítom magam ilyen kegyetlen kihívások elé… Az én neszesszerem amúgy fél mázsás lenne, ha nem tartanám szigorú kontroll alatt.' }
              url={ 'mi-van-agi-neszesszereben' }
            />

            <BlogItem
              image={ '/images/blog/kedvenc-termekeim-2017.jpg' }
              tags={ '2017, kedvencek, smink' }
              title={ 'Kedvenc termékeim 2017-ben' }
              time={ '2018. február 6.' }
              lead={ 'Nagyon nehéz volt leszűkítenem a listát, mert annyi szuper termék található a piacon.' }
              url={ 'agi-kedvenc-termekei-2017-ben' }
            />

            <BlogItem
              image={ '/images/blog/the-makeuplab-fb-csoport.jpg' }
              tags={ 'facebook, csoport, smink' }
              title={ 'The Makeup Lab – Sminkinspirációs Facebook csoport' }
              time={ '2018. január 19.' }
              lead={ 'Régóta terveztem egy olyan zárt Facebook csoport létrehozását, ahol valódi és használható sminkötleteket adhatunk egymásnak a mindennapokra.' }
              url={ 'the-makeup-lab-sminkinspiracios-facebook-csoport' }
            />

            <BlogItem
              image={ '/images/blog/neon-smink.jpg' }
              tags={ 'smunk, neon, tippek' }
              title={ 'Neon smink' }
              time={ '2017. december 14.' }
              lead={ 'Ti is kockázatosnak tartjátok az erőteljes színeket egy sminkben? Szeretném megmutatni, hogyan lehet “megszelídíteni” akár a neon színeket is. ' }
              url={ 'neon-smink' }
            />

            <BlogItem
              image={ '/images/blog/smink-kisokos-primerek.jpg' }
              tags={ 'smink, neon, motd' }
              title={ 'Smink kisokos: Primerek' }
              time={ '2017. december 7.' }
              lead={ 'Mi az a primer? Milyen fajtái vannak? Mire jó egyáltalán? Ha felmerültek már benned ezek a kérdések, akkor biztos jól jön majd ez a kis segédlet.' }
              url={ 'smink-kisokos-primerek' }
            />

            <BlogItem
              image={ '/images/blog/must-have-drogerias-termekek.jpg' }
              tags={ 'drogéria, smink, low budget' }
              title={ 'Must have drogériás termékek sminkeseknek' }
              time={ '2017. november 20. ' }
              lead={ 'Kezdő sminkes vagy és egyelőre nem engedheted meg magadnak, hogy kizárólag high-end termékeket vásárolj a kit-edbe?' }
              url={ 'must-have-drogerias-termekek-sminkeseknek' }
            />

            <BlogItem
              image={ '/images/blog/maybeline-new-york-the-nudes.jpg' }
              tags={ 'smink, motd, maybeline' }
              title={ 'Maybelline The Nudes paletta' }
              time={ '2017. október 29.' }
              lead={ 'Szerettem volna egy pénztárcabarát megoldást is kínálni a szemhéjpúder paletták tesztjei során, ezért  beszerztem egy drogériás terméket is.' }
              url={ 'maybelline-new-york-the-nudes-paletta' }
            />

            <BlogItem
              image={ '/images/blog/mac-semi-sweet-times-nine-paletta.jpg' }
              tags={ 'smunk, mac, teszt' }
              title={ 'MAC Semi-Sweet Times Nine paletta' }
              time={ '2017. október 19.' }
              lead={ 'Folytatjuk a paletta teszt sorozatunkat. Ezúttal egy olyan gyöngyszemre esett a választásunk, ami könnyen elérhető itthon, de nem drogériás árkategória.' }
              url={ 'mac-semi-sweet-times-nine-paletta' }
            />

            <BlogItem
              image={ '/images/blog/the-makeup-lab-szolgaltatasai-halloween-smink.jpg' }
              tags={ 'smink, tml, halloween' }
              title={ 'The Makeup Lab szolgáltatásai: Halloween smink' }
              time={ '2017. október 17.' }
              lead={ 'Halloween-i buliba készülsz és idén valami ütős karaktert álmodtál meg, de a kivitelezés nem is olyan egyszerű? Ilyenkor jól jön a szakmai segítség!' }
              url={ 'the-makeup-lab-szolgaltatasai-halloween-smink' }
            />

            <BlogItem
              image={ '/images/blog/letesztelve-sminkszivacsok-ii.jpg' }
              tags={ 'teszt, szivacsok' }
              title={ 'Letesztelve: Sminkszivacsok II.' }
              time={ '2017. október 15.' }
              lead={ 'Összeszedtem a tapasztalataimat a sminkszivacsok kapcsán és megosztom veletek a szivacs megfelelő tisztítását is.' }
              url={ 'letesztelve-sminkszivacsok-ii' }
            />

            <BlogItem
              image={ '/images/blog/letesztelve-micellas-vizek-ii.jpg' }
              tags={ 'teszt, bioderma' }
              title={ 'Letesztelve: Micellás vizek II.' }
              time={ '2017. október 9.' }
              lead={ 'Jöjjön most a várva várt második része a micellás víz tesztnek. A visszajelzéseitek alapján már vártátok a folytatást, hogy teljes képet kapjatok.' }
              url={ 'letesztelve-micellas-vizek-ii' }
            />

            <BlogItem
              image={ '/images/blog/urban-decay-naked-heat-paletta.jpg' }
              tags={ 'smink, paletta, tippek' }
              title={ 'Urban Decay Naked Heat paletta' }
              time={ '2017. szeptember 25.' }
              lead={ 'A kereskedelemben eltöltött éveim alatt azt tapasztaltam, hogy a nők teljesen tanácstalanok, ha szemhéjpúder vásárlásáról van szó.' }
              url={ 'urban-decay-naked-heat-paletta' }
            />

            <BlogItem
              image={ '/images/blog/letesztelve-micellas-vizek-i.jpg' }
              tags={ 'teszt, bioderma' }
              title={ 'Letesztelve: Micellás vizek I.' }
              time={ '2017. szeptember 22.' }
              lead={ 'Mi az a micellás víz? Mire is jó pontosan? Melyik márkát válasszuk? Melyik a legjobb?' }
              url={ 'letesztelve-micellas-vizek-i' }
            />

            <BlogItem
              image={ '/images/blog/letesztelve-sminkszivacsok-i.jpg' }
              tags={ 'teszt, szivacsok' }
              title={ 'Letesztelve: Sminkszivacsok I.' }
              time={ '2017. szeptember 21.' }
              lead={ 'Mire jók a sminkszivacsok? Hogy kell őket használni? Hogyan tisztítsuk őket?' }
              url={ 'letesztelve-sminkszivacsok-i' }
            />

            <BlogItem
              image={ '/images/blog/eskuvoi-probasmink-tenyleg-annyira-fontos.jpg' }
              tags={ 'smink, esküvő' }
              title={ 'Esküvői próbasmink? Tényleg annyira fontos?' }
              time={ '2017. július 22.' }
              lead={ 'Biztos, hogy annyira fontos lepróbálni a sminket az esküvő előtt? Mi van, ha nem tetszik a próbasmink és nem szeretném azt a sminkest választani?' }
              url={ 'eskuvoi-probasmink-tenyleg-annyira-fontos' }
            />

            <BlogItem
              image={ '/images/blog/szemoldokformazas.jpg' }
              tags={ 'smink, tippek, szemöldök' }
              title={ 'Szemöldökformázás' }
              time={ '2017. július 20.' }
              lead={ 'Szeretnéd tudni, hogy milyen lehetőségeid vannak? Mivel érhetsz el természetes hatást vagy épp ellenkezőleg, megbabonáz az Instagram-szemöldök?' }
              url={ 'szemoldokformazas' }
            />

            <BlogItem
              image={ '/images/blog/miert-dragabb-az-eskuvoi-smink.jpg' }
              tags={ 'smink, esküvő' }
              title={ 'Miért drágább az esküvői smink?' }
              time={ '2017. július 17.' }
              lead={ 'Tényleg, miért is drágább a többinél? Azok nem olyan tartósak? Miért van szükség a próbasminkre? Hogyan válassz sminkest a nagy napra?' }
              url={ 'miert-dragabb-az-eskuvoi-smink' }
            />

            <BlogItem
              image={ '/images/blog/the-makeup-lab-szolgaltatasai-alkalmi-smink.jpg' }
              tags={ 'smink, tml, alkalmi' }
              title={ 'The Makeup Lab szolgáltatásai: Alkalmi smink' }
              time={ '2017. július 3.' }
              lead={ 'Fontos eseményre készülsz? Esküvőre vagy hivatalos? Bulizni mentek a csajokkal? Csak pár olyan alkalom, amikor jól jönne egy profi smink.' }
              url={ 'the-makeup-lab-szolgaltatasai-alkalmi-smink' }
            />

            <BlogItem
              image={ '/images/blog/the-makeup-lab-szolgaltatasai-egyeni-sminktanacsadas.jpg' }
              tags={ 'smink, tml, tanácsadás' }
              title={ 'The Makeup Lab szolgáltatásai: Egyéni sminktanácsadás' }
              time={ '2017. június 23.' }
              lead={ '"Hogyan sminkeljem magam?" – Egyéni sminktanácsadás és oktatás. Miért válassz pont engem? Miért is lesz ez a szolgáltatás hasznos a számodra?' }
              url={ 'the-makeup-lab-szolgaltatasai-egyeni-sminktanacsadas' }
            />

            <BlogItem
              image={ '/images/blog/agi-5-perces-sminkje.jpg' }
              tags={ 'smink, tippek' }
              title={ 'Ági 5 perces sminkje' }
              time={ '2017. május 11.' }
              lead={ 'Most megosztom veletek a részleteket arról a sminkről, amit a nagyon rohanós reggeleken szoktam bevetni.' }
              url={ 'agi-5-perces-sminkje' }
            />

            <BlogItem
              image={ '/images/blog/keszulj-velunk-a-joy-napokra.jpg' }
              tags={ 'smink, joy napok, kupon' }
              title={ 'Készülj Velem a JOY-napokra!' }
              time={ '2017. április 12.' }
              lead={ 'Lehet, hogy még fel sem ocsúdtatok az előző kuponos őrület után, és máris itt a következő.' }
              url={ 'keszulj-velunk-a-joy-napokra' }
            />

            <BlogItem
              image={ '/images/blog/letesztelve-tested-bioderma-ii.jpg' }
              tags={ 'teszt, bioderma' }
              title={ 'Letesztelve: Bioderma II.' }
              time={ '2017. március 20.' }
              lead={ 'A mai nap folytatom a Bioderma termékek tesztjét, most a Sensibio családból választottam.' }
              url={ 'letesztelve-tested-bioderma-ii' }
            />

            <BlogItem
              image={ '/images/blog/kerlek-ne-tedd-please-just-dont.jpg' }
              tags={ 'smink, tippek' }
              title={ 'Kérlek, ne tedd!' }
              time={ '2017. március 19.' }
              lead={ 'Ma három olyan bakit hoztunk Nektek, ahol komolyan elgondolkodtunk rajta, hogy miért nem érzik celebjeink a tükörbe nézve, hogy valami nem oké!?' }
              url={ 'kerlek-ne-tedd-please-just-dont' }
            />

            <BlogItem
              image={ '/images/blog/kornyezettudatos-markak-environmentally-conscious-brands.jpg' }
              tags={ 'környezet, márkák' }
              title={ 'Környezettudatos márkák' }
              time={ '2017. március 17.' }
              lead={ 'Egy számomra fontos témáról szeretnénk írni és felhívni a Ti figyelmeteket is a lehetőségekre, amivel mindenki csak nyer.' }
              url={ 'kornyezettudatos-markak-environmentally-conscious-brands' }
            />

            <BlogItem
              image={ '/images/blog/mac-shadescents.jpg' }
              tags={ 'MAC, teszt' }
              title={ 'MAC SHADESCENTS' }
              time={ '2017. február 19.' }
              lead={ 'Minden túlzás nélkül kijelenthetjük, hogy a MAC több ikonikus árnyalatú rúzst is a piacra dobott az évek során. Ezek közül válogattam.' }
              url={ 'mac-shadescents' }
            />

            <BlogItem
              image={ '/images/blog/grammy-awards-2017.jpg' }
              tags={ 'grammy, 2017' }
              title={ 'Grammy Awards 2017' }
              time={ '2017. február 15.' }
              lead={ 'Az év elején zajlanak a legfontosabb vörös szőnyeges események, többek között a legrangosabb zenei díjátadó, a Grammy Awards is. Ismét szemezgettem a sminkek között.' }
              url={ 'grammy-awards-2017' }
            />

            <BlogItem
              image={ '/images/blog/sminktippek-valentin-napra-makeup-tips-for-valentines-day.jpg' }
              tags={ 'tippek, smink, valentin' }
              title={ 'Sminktippek Valentin napra' }
              time={ '2017. február 8.' }
              lead={ 'Vészesen közeleg Valentin nap, a szerelmesek ünnepe, ezért úgy gondoltuk, mutatunk Nektek két sminket, amivel újra elcsavarhatjátok a párotok fejét.' }
              url={ 'sminktippek-valentin-napra-makeup-tips-for-valentines-day' }
            />

            <BlogItem
              image={ '/images/blog/tedd-es-ne-tedd-dos-and-donts-5.gif' }
              tags={ 'smink, tippek' }
              title={ 'Tedd! és Ne tedd! - 5' }
              time={ '2017. február 5.' }
              lead={ 'Ismét jelentkezünk pár sminkbakival, amit jobb, ha elkerültök! ' }
              url={ 'tedd-es-ne-tedd-dos-and-donts-5' }
            />

            <BlogItem
              image={ '/images/blog/mikre-figyelj-mielott-befizetsz-egy-sminkes-tanfolyamra.jpg' }
              tags={ 'smink, tanfolyam, tippek' }
              title={ 'Mikre figyelj mielőtt befizetsz egy sminkes tanfolyamra!' }
              time={ '2017. január 30.' }
              lead={ 'Senki sincs egyszerű helyzetben, mert aki tanfolyamot keres, annyi iskolával és opcióval találja szembe magát, hogy ember legyen a talpán, aki választani tud.' }
              url={ 'mikre-figyelj-mielott-befizetsz-egy-sminkes-tanfolyamra' }
            />

            <BlogItem
              image={ '/images/blog/tedd-es-ne-tedd-dos-and-donts-4.jpg' }
              tags={ 'smink, tippek' }
              title={ 'Tedd! és Ne tedd! - 4' }
              time={ '2017. január 29.' }
              lead={ 'Ezen a héten sem maradtok jótanácsok nélkül, a múlt heti poszt után ismét sminkesebb témák felé vesszük az irányt.' }
              url={ 'tedd-es-ne-tedd-dos-and-donts-4' }
            />

            <BlogItem
              image={ '/images/blog/tedd-es-ne-tedd-dos-and-donts-3.jpg' }
              tags={ 'smink, tippek' }
              title={ 'Tedd! és Ne tedd! - 3' }
              time={ '2017. január 22.' }
              lead={ 'Hamar elrepült ez a hét is, ugye? Több arc is a kezeim közé került a napokban, és a beszélgetések újabb témákat hoztak, amit úgy érzek, muszáj tisztázni.' }
              url={ 'tedd-es-ne-tedd-dos-and-donts-3' }
            />

            <BlogItem
              image={ '/images/blog/mac-x-caitlyn-jenner-swatchok-mac-x-caitlyn-jenner-swatches.jpg' }
              tags={ 'smink, MAC, Caitlyn' }
              title={ 'MAC x Caitlyn Jenner swatchok' }
              time={ '2017. január 18.' }
              lead={ 'Nem bírtam ki sokáig, múlt hét pénteken elmentem a Váci utcai MAC üzletbe, hogy kipróbálhassam a MAC x Caitlyn Jenner kollekció darabjait.' }
              url={ 'mac-x-caitlyn-jenner-swatchok-mac-x-caitlyn-jenner-swatches' }
            />

            <BlogItem
              image={ '/images/blog/tedd-es-ne-tedd-dos-and-donts-2.jpg' }
              tags={ 'smink, tippek' }
              title={ 'Tedd! és Ne tedd! - 2' }
              time={ '2017. január 16.' }
              lead={ 'Ismét gyűjtöttünk néhány hibát, ami egy kis odafigyeléssel elkerülhető lenne, mégis sokan elkövetik.' }
              url={ 'tedd-es-ne-tedd-dos-and-donts-2' }
            />

            <BlogItem
              image={ '/images/blog/letesztelve-tested-vichy-dermablend-fluid-corrective-foundation-16hr.jpg' }
              tags={ 'teszt, vichy' }
              title={ 'Letesztelve: Vichy DERMABLEND Fluid Corrective Foundation 16HR' }
              time={ '2017. január 15.' }
              lead={ 'Az előző bejegyzés folytatásaként most jöjjön egy másik Vichy alapozó teszt.' }
              url={ 'letesztelve-tested-vichy-dermablend-fluid-corrective-foundation-16hr' }
            />

            <BlogItem
              image={ '/images/blog/letesztelve-tested-vichy-dermablend-3d-correction.jpg' }
              tags={ 'teszt, vichy' }
              title={ 'Letesztelve: Vichy DERMABLEND [3D CORRECTION]' }
              time={ '2017. január 13.' }
              lead={ 'Megfelelő alapozás nélkül a sminkünk sem lesz tökéletes, sokféle alapozó közül választhatunk.' }
              url={ 'letesztelve-tested-vichy-dermablend-3d-correction' }
            />

            <BlogItem
              image={ '/images/blog/erett-nok-sminktrukkjei-mature-women-makeup-tricks.jpg' }
              tags={ 'smink, tippek' }
              title={ 'Érett nők sminktrükkjei' }
              time={ '2017. január 11.' }
              lead={ 'Befutott hozzánk több olyan kérés, hogy adjunk pár sminktippet érettebb nők számára kifejezetten.' }
              url={ 'erett-nok-sminktrukkjei-mature-women-makeup-tricks' }
            />

            <BlogItem
              image={ '/images/blog/mac-x-caitlyn-jenner.gif' }
              tags={ 'caitlyn, MAC' }
              title={ 'MAC x Caitlyn Jenner' }
              time={ '2017. január 10.' }
              lead={ 'A MAC Cosmetics szeret különc karaktereket választani a kollekcióik élére.' }
              url={ 'mac-x-caitlyn-jenner' }
            />

            <BlogItem
              image={ '/images/blog/golden-globe-awards-2017.jpg' }
              tags={ 'golden globe, 2017' }
              title={ 'Golden Globe Awards 2017' }
              time={ '2017. január 9.' }
              lead={ 'Itteni idő szerint tegnap éjjel tartották a 74. Golden Globes gálát Beverly Hills-ben.' }
              url={ 'golden-globe-awards-2017' }
            />

            <BlogItem
              image={ '/images/blog/tedd-es-ne-tedd-dos-and-donts.jpg' }
              tags={ 'smink, tippek' }
              title={ 'Tedd! és Ne tedd!' }
              time={ '2017. január 8.' }
              lead={ 'Terveink szerint heti rendszerességgel szeretnénk majd Nektek tippeket adni smink témában.' }
              url={ 'tedd-es-ne-tedd-dos-and-donts' }
            />

            <BlogItem
              image={ '/images/blog/folyekony-ruzs-teszt-liquid-lipstick-test.jpg' }
              tags={ 'rúzs, teszt' }
              title={ 'Folyékony rúzs teszt' }
              time={ '2017. január 6.' }
              lead={ 'A mattra száradó folyékony rúzsok már ostromolják a piacot egy ideje, de a 2016-os év slágerterméke kétség nélkül ők voltak.' }
              url={ 'folyekony-ruzs-teszt-liquid-lipstick-test' }
            />

            <BlogItem
              image={ '/images/blog/csomagunk-erkezett-kiko-milano-kiko-milano-product-test.jpg' }
              tags={ 'kiko milano, teszt' }
              title={ 'Csomagunk érkezett: Kiko Milano' }
              time={ '2017. január 5.' }
              lead={ 'Ahogy a hétvégén már említettük, múlt héten megérkezett a Kiko Milano rendelésünk.' }
              url={ 'csomagunk-erkezett-kiko-milano-kiko-milano-product-test' }
            />

            <BlogItem
              image={ '/images/blog/ecsetek-alapok-brushes-basics.jpg' }
              tags={ 'tippek, ecsetek' }
              title={ 'Ecsetek – Alapok' }
              time={ '2017. január 2.' }
              lead={ 'Az ecsetek mindig kimeríthetetlen témaforrást jelentenek. Könnyen zavarba tud jönni az ember, hiszen annyi variációt tolnak az orrunk elé. ' }
              url={ 'ecsetek-alapok-brushes-basics' }
            />

          </div>
        </div>
      </div>
    );
  }
}

export default BlogList;
