import { POSITION, COLORS, FONTS } from '../styles';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  heroWrapper: {
    position: POSITION.RELATIVE,
    width: '100%',
    height: '70vh',
    minHeight: 300,
    maxHeight: 600,
    background: COLORS.WHITE,

    ':nth-child(n) > button': {
      opacity: 0,
      transition: 'opacity .3s ease-out',
    },

    ':nth-child(n):hover > button': {
      opacity: 1,
    },

    '@media (max-width: 640px)': {
      height: '30vh',
      minHeight: 250,
      maxHeight: 450,
    },

    ':after': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: '100%',
      height: 80,
      bottom: 0,
      left: 0,
      zIndex: 1,
      transform: 'rotateZ(0deg)',
      transformOrigin: '100% 0 0',
      background: `linear-gradient(to top right, ${COLORS.GRAY4} 50%, ${COLORS.TRANSPARENT} 51%)`,

      '@media (max-width: 768px)': {
        display: 'none',
      },

    },

  },

  carouselWrapper: {
    position: POSITION.RELATIVE,
    height: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    listStyle: 'none',
    padding: 0,
    zIndex: 1,
  },

  swiperWrapper: {
    position: POSITION.RELATIVE,
    display: 'flex',
    width: '100%',
    height: '100%',
    zIndex: 1,
    boxSizing: 'content-box',
    transitionTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
  },

  slide: {
    position: POSITION.RELATIVE,
    flexShrink: 0,
    width: '100%',
    height: '100%',
    background: COLORS.GRAY2,

    ':before': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: '100%',
      height: '100%',
      zIndex: 10,
      opacity: .3,
      background: 'repeating-linear-gradient(-62deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4) 2px, transparent 2px, transparent 4px)',

      '@media (max-width: 768px)': {
        background: 'repeating-linear-gradient(-62deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4) 2px, transparent 2px, transparent 3px)',
      },

    },

  },

  slideBg: {
    position: POSITION.ABSOLUTE,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    objectPosition: 'center center',
    objectFit: 'cover',
  },

  slideBgTablet: {
    position: POSITION.ABSOLUTE,
    display: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 10,
    objectPosition: 'center center',
    objectFit: 'cover',

    '@media (max-width: 768px)': {
      display: 'block',
    },

  },

  navigation: {
    position: POSITION.ABSOLUTE,
    width: '100%',
    height: 200,
    top: '50%',
    left: '50%',
    maxWidth: 1200,
    margin: 0,
    transform: 'translate(-50%, -50%)',
  },

  carouselButton: {
    position: POSITION.ABSOLUTE,
    width: 80,
    height: 180,
    top: '50%',
    padding: 0,
    zIndex: 15,
    border: 0,
    cursor: 'pointer',
    outline: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textIndent: '110%',
    background: 'none',
    transform: 'translateY(-50%)',

    '@media (max-width: 768px)': {
      display: 'none',
    },

    ':hover:before': {
      background: COLORS.PINK,
    },

    ':hover:after': {
      background: COLORS.PINK,
    },

    ':before': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: 8,
      height: 90,
      top: 2,
      transformOrigin: 'bottom center',
      background: COLORS.PINK_DARK,
      transition: 'background-color .3s ease-out, transform .3s ease-out',
    },

    ':after': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: 8,
      height: 90,
      bottom: 2,
      transformOrigin: 'top center',
      background: COLORS.PINK_DARK,
      transition: 'background-color .3s ease-out, transform .3s ease-out',
    },

  },

  carouselButtonLeft: {
    left: 'calc((100vw - 1200px) / 2)',

    ':before': {
      left: 0,
      transform: 'translateX(4px) rotateZ(28deg)',
    },

    ':after': {
      left: 0,
      transform: 'translateX(4px) rotateZ(-28deg)',
    },

    ':hover:before': {
      transform: 'translateX(0) rotateZ(28deg)',
    },

    ':hover:after': {
      transform: 'translateX(0) rotateZ(-28deg)',
    },

    '@media (max-width: 1200px)': {
      left: 20,
    },

  },

  carouselButtonRight: {
    right: 'calc((100vw - 1200px) / 2)',

    ':before': {
      right: 0,
      transform: 'translateX(-4px) rotateZ(-28deg)',
    },

    ':after': {
      right: 0,
      transform: 'translateX(-4px) rotateZ(28deg)',
    },

    ':hover:before': {
      transform: 'translateX(0) rotateZ(-28deg)',
    },

    ':hover:after': {
      transform: 'translateX(0) rotateZ(28deg)',
    },

    '@media (max-width: 1200px)': {
      right: 20,
    },

  },

  logoWrapper: {
    position: POSITION.ABSOLUTE,
    width: 200,
    height: 200,
    left: '60%',
    top: '50%',
    zIndex: 10,
    color: 'transparent',
    background: COLORS.WHITE,
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, .3)',
    transform: 'translate(-50%, -50%)',

    '@media (max-width: 640px)': {
      width: 80,
      height: 80,
    },

  },

  logo: {
    width: '100%',
    height: '100%',
  },

  titleWrapper: {
    position: POSITION.ABSOLUTE,
    width: '100%',
    maxWidth: 1000,
    padding: '0 80px',
    top: '50%',
    left: '50%',
    zIndex: 20,
    transform: 'translate(-50%, -50%)',
  },

  title: {
    ...FONTS.OPEN_SANS,
    fontSize: 40,
    fontWeight: 600,
    lineHeight: 1.2,
    textAlign: 'right',
  },

  titleLight: {
    color: COLORS.WHITE,
  },

  hero2Title: {
    fontSize: 40,

    '@media (max-width: 640px)': {
      fontSize: 24,
      lineHeight: 1.1,
    },

  },

});
