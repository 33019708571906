import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import HeroImage from '../components/HeroImage';
import s from './Services.style';

class Services extends Component {
  state = {};

  render() {
    return (
      <section>
        <HeroImage
          title={ 'Szolgáltatásaim' }
          image={ [process.env.PUBLIC_URL + '/images/services-hero.jpg'] }
        />
        <div className={ css(s.contentWrapper) }>

          {/* Alap sminkes tanfolyam */}
          <div className={ css(s.contentBlock) }>
            <div className={ css(s.contentWrapperInner) }>
              <h2>Alap sminkes tanfolyam</h2>
              <h6>Ténylegesen használható sminktudás</h6>
              <span className={css(s.contentInfo)}>16 x 4 óra / 360 000 Ft</span>
              <p>4 hónapos a tanfolyam, 1 alkalom 4 órás.</p>
              <p>Tanulóimnak egyszeri <b>40%-os kedvez-<br />ményes vásárlásra</b> van lehetőségük
                 a MAC-ben.
              </p>
              <p>Érdekel a sminkes szakma, de el vagy veszve, hogy melyik tanfolyamot válaszd?
                 Annyit ígérhetek, hogy olyan tudást kapsz Tőlem, amivel magabiz-<br />tosan fogsz
                 tudni sminkelni.</p>
              <p>Semmi sallang, semmi felesleges információ. Kizárólag tisztán használható
                 tudás! Segítek abban is, hogy hogyan indulj el a szakmában. (Marketing
                 stratégiák, brand építés, stb.)</p>
              <p>További részletekért keress privát üzenetben.</p>
            </div>
          </div>

          {/* Fix tematika */}
          <div className={ css(s.contentBlock) }>
            <div className={ css(s.contentWrapperInner) }>
              <h2>Fix tematikájú workshopok sminkeseknek</h2>
              <h6>Sminkeseknek, kozmetikusoknak</h6>
              <span className={css(s.contentInfo)}>6 óra / 30 000 Ft - 45 000 Ft</span>
              <p>Csoportos workshopok végzett
                 sminkeseknek / kozmetikusoknak / szépségtanácsadóknak.</p>
              <p>Milyen workshop témák vannak:</p>
              <ul>
                <li>Esküvői smink</li>
                <li>Facechart</li>
                <li>Színelmélet és színkorrekció</li>
                <li>Szemöldök</li>
                <li>Kontúrozás és highlightolás, acformák elemzése</li>
                <li>Szemsminkek, liner technikák, szemformák elemzése</li>
                <li>Alap SFX (horzsolások, sebek, hegek, golyó lőtte sebek, vágások, zúzódások)
                </li>
              </ul>
              <p>További részletekért keress privát üzenetben.</p>
            </div>
          </div>

          {/* Egyeni tovabbkepzes sminkeseknek */}
          <div className={ css(s.contentBlock) }>
            <div className={ css(s.contentWrapperInner) }>
              <h2>Egyéni továbbképzés sminkeseknek</h2>
              <h6>Továbbképzés</h6>
              <span className={css(s.contentInfo)}>4 - 6 óra / 8 000 Ft / óra</span>
              <p>Végzett sminkes / kozmetikus / szépségtanácsadó vagy, de szeretnéd kicsit
                 frissíteni a tudásodat, több technikát elsajátítani úgy, hogy teljesen rád legyen
                 szabva az oktatási anyag?</p>
              <p>Egyszemélyes találkozó alkalmával egyeztetjük az elképzeléseidet, ami alapján
                 összeállítok Neked egy tematikát, becsülök rá időt és elküldöm az ajánlatomat.
                 Természetesen ezen mindig lehet módosítani. Aztán indulhat is a menet! ;)</p>
              <p>1 alkalom maximum 6 óra.</p>
              <p>További részletekért keress privát üzenetben.</p>
            </div>
          </div>

          {/* Egyeni smink */}
          <div className={ css(s.contentBlock) }>
            <div className={ css(s.contentWrapperInner) }>
              <h2>Egyéni sminktanácsadás és oktatás</h2>
              <h6>Személyre szabva, tippek és trükkök</h6>
              <span className={css(s.contentInfo)}>3 - 4 óra / 30 000 Ft</span>
              <p>Maximálisan személyre van szabva, nem én mondom meg, hogy mit fogsz tanulni,
                 hanem Te mondod meg nekem, hogy mit szeretnél elsajátítani. Az oktatást követően
                 részletes összefoglalót kapsz a tanultakról, minden apró trükkről.</p>
              <p>Ha szeretnél jobban / gyorsabban / tudatosabban sminkelni, szeretnél olyan
                 trükköket eltanulni, amivel megkönnyíted a reggeli készülődést vagy szeretnél
                 kicsit jobban odafigyelni a megjelenésedre, az arcápolási rutinodra, akkor
                 az <b>Egyéni smink-<br />tanácsadás</b> Neked való.
              </p>
              <p>További részletekért keress privát üzenetben.</p>
            </div>
          </div>

          {/* Alkalmi smink */}
          <div className={ css(s.contentBlock) }>
            <div className={ css(s.contentWrapperInner) }>
              <h2>Alkalmi smink</h2>
              <h6>Hivatalos eseményre, buliba, esküvőre</h6>
              <span className={css(s.contentInfo)}>12 000 Ft</span>
              <p>
                Fontos eseményre készülsz? Esküvőre vagy hivatalos? Bulizni mentek a csajokkal? Csak
                pár olyan alkalom, amikor jól jönne egy profi smink.</p>
              <p>Alkalmi smink kategóriába tartozik:</p>
              <ul>
                <li>Nappali smink</li>
                <li>Esti smink</li>
                <li>Szalagavató smink</li>
                <li>Smink fotózásra kiszállás nélkül</li>
                <li>Smink fitness versenyre kiszállás nélkül</li>
              </ul>
              <p>A műszempilla felhelyezése feláras.</p>
            </div>
          </div>

          {/* Csoportos tanacsadas */}
          <div className={ css(s.contentBlock) }>
            <div className={ css(s.contentWrapperInner) }>
              <h2>Csoportos tanácsadás</h2>
              <h6>Tudásfrissítés</h6>
              <span className={css(s.contentInfo)}>2 - 4 óra / 6 000 - 8 000 Ft / fő</span>
              <p>Átfogó szépségápolási és sminktanácsadás, ami tökéletes program lehet
                 lánybúcsúra, csapatépítőre, csajos programnak.</p>
              <p>A szolgáltatás időtartama 2-4 óra között a létszámtól függően.</p>
              <p>1-4 fő között a tanácsadás ára 5.000 Ft/fő, 5 főtől pedig 4.000 Ft/fő</p>
              <p>Mi van az árban?</p>
              <ul>
                <li>Összefoglaló a tanultakról</li>
                <li>Welcome drink</li>
                <li>Nasi</li>
              </ul>
            </div>
          </div>

          {/* Alap SFX */}
          <div className={ css(s.contentBlock) }>
            <div className={ css(s.contentWrapperInner) }>
              <h2>Alap SFX</h2>
              <h6>Halloween-i, farsangi smink</h6>
              <span className={css(s.contentInfo)}>1,5 óra / 15 000 Ft</span>
              <p>Halloween-i vagy farsangi buliba vagy hivatalos és fejben már megvan a karakter,
                 de kéne egy király smink, esetleg hegek, sebek, vágások hozzá?</p>
              <p>Akkor ez neked van kitalálva! ;)</p>
            </div>
          </div>

          {/* Teszt fotozas */}
          <div className={ css(s.contentBlock) }>
            <div className={ css(s.contentWrapperInner) }>
              <h2>Teszt fotózás / Portfólió fotózás</h2>
              <h6>Smink</h6>
              <span className={css(s.contentInfo)}>45 perc+ / 12 000 Ft - 15 000 Ft</span>
              <p>1 smink - 12 000 Ft</p>
              <p>+1 ráépített smink - 15 000 Ft</p>
              <p>+2 ráépített smink - 17 500 Ft</p>
              <p>A kiszállás NINCS benne az árban.</p>
            </div>
          </div>

          {/* Muszempilla */}
          <div className={ css(s.contentBlock) }>
            <div className={ css(s.contentWrapperInner) }>
              <h2>Műszempilla felhelyezés</h2>
              <h6>Smink, kiszállás</h6>
              <span className={css(s.contentInfo)}>15 perc / 5 000 Ft</span>
              <p>Kizárólag minőségi, természetes, kényelmes szempillákat használok.</p>
              <p>A pilla dobozát minden esetben odaadom, így többször is fel lehet használni
                 őket.</p>
            </div>
          </div>

          {/* Eskuvoi smink */}
          <div className={ css(s.contentBlock) }>
            <div className={ css(s.contentWrapperInner) }>
              <h2>Esküvői smink csomag</h2>
              <h6>Konzultáció, próbasmink</h6>
              <span className={css(s.contentInfo)}>1,5 - 3 óra / 35 000 Ft</span>
              <p>Mit tartalmaz?</p>
              <ul>
                <li>Részletes konzultációt</li>
                <li>Próbasminket a stúdiómban (20 000 Ft)</li>
                <li>Budapest területén kiszállást</li>
                <li>Sminked az esküvő napján (15 000 Ft)</li>
                <li>Vőlegény arcának lemattítását</li>
              </ul>
              <p>További feláras szolgáltatások:</p>
              <ul>
                <li>Vendégek sminkje - 7 000 Ft/fő</li>
                <li>Műszempilla felhelyezés - 3 000 - 7 000 Ft között/fő</li>
                <li>Smink kreatív fotózásra - 12 000 Ft</li>
                <li>Készenléti felár fotózásra - 2000 Ft/óra</li>
                <li>Vidéki kiszállás - egyéni megállapodás alapján</li>
              </ul>
              <p>A szolgáltatás időtartama a létszámtól és a megrendelt szolgáltatásoktól
                 függ.</p>
              <p>További részletekért keress privát üzenetben.</p>
            </div>
          </div>

          {/* Ajandekutalvany */}
          <div className={ css([s.contentBlock, s.contentBlockWithoutDecor]) }>
            <div className={ css(s.contentWrapperInner) }>
              <h2>Ajándékutalvány</h2>
              <h6>Ajándékozz élményt</h6>
              <span className={css(s.contentInfo)}></span>
              <p>Minden szolgáltatás megvásárolható ízléses, névre szóló ajándékutalvány
                 formájában.</p>
              <p>A legjobb döntés élményt ajándékozni valakinek, nem egy újabb porfogót a
                 polcra.</p>
            </div>
          </div>

        </div>
      </section>
    );
  }
}

export default Services;
