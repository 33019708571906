import { POSITION, COLORS, FONTS } from '../styles';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  loader: {
    position: POSITION.ABSOLUTE,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },

  wrapper: {
    position: POSITION.RELATIVE,
    display: 'block',
  },

  mapWrapper: {
    position: POSITION.RELATIVE,
    width: '100%',
    height: 480,

    ':after': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: '100%',
      height: 80,
      top: 0,
      left: 0,
      zIndex: 101,
      pointerEvents: 'none',
      transform: 'rotateZ(0deg)',
      transformOrigin: '100% 0 0',
      background: `linear-gradient(to bottom right, ${COLORS.WHITE} 50%, ${COLORS.TRANSPARENT} 51%)`,

      '@media (max-width: 1000px)': {
        width: '100%',
        left: 0,
      },

      '@media (max-width: 768px)': {
        display: 'none',
      },

    },

    '@media (max-width: 768px)': {
      height: 400,
    },

  },

  contactWrapper: {
    position: POSITION.ABSOLUTE,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 110,
    pointerEvents: 'none',
    transition: 'opacity .3s linear',
  },

  contactInfo: {
    position: POSITION.ABSOLUTE,
    width: '30%',
    top: 'calc(50% + 40px)',
    left: '50%',
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.4,
    ...FONTS.OPEN_SANS,
    color: COLORS.WHITE,
    transform: 'translateY(-50%)',

    '@media (max-width: 768px)': {
      top: '50%',
    },

    '@media (max-width: 640px)': {
      width: '80%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },

  },

  containerWrapper: {
    position: POSITION.RELATIVE,
    width: '100%',
    height: '100%',

    ':hover:after': {
      opacity: 0,
    },

    ':nth-child(1n):hover + div': {
      opacity: 0,
    },

    ':after': {
      position: POSITION.ABSOLUTE,
      content: '""',
      top: 1,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 100,
      pointerEvents: 'none',
      transition: 'opacity .3s linear',
      background: 'rgba(0, 0, 0, .55)',

    },

  },
});
