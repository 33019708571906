import { POSITION, FONTS, COLORS } from '../styles';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({

  blogItemWrapper: {
    position: POSITION.RELATIVE,
    display: 'block',
    flex: '0 0 50%',
    padding: 10,

    '@media (max-width: 768px)': {
      flex: '0 0 100%',
    },

  },

  blogItemWrapperInner: {
    position: POSITION.RELATIVE,
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'stretch',
    textDecoration: 'none',
    background: `linear-gradient(to top, rgba(255, 41, 132, .03) 0%, ${COLORS.TRANSPARENT} 50%, rgba(255, 41, 132, .03) 100%)`,
    transform: 'scale(1)',
    transition: 'transform .4s, background .3s',
    transitionTimingFunction: 'cubic-bezier(0.445, 0.05, 0.55, 0.95);',

    ':before': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: 40,
      height: 2,
      top: 0,
      right: 0,
      opacity: 0,
      zIndex: 50,
      background: COLORS.PINK,
      transition: 'transform .4s, opacity .3s',
      transitionTimingFunction: 'cubic-bezier(0.445, 0.05, 0.55, 0.95);',

    },

    ':after': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: 2,
      height: 40,
      top: 0,
      right: 0,
      opacity: 0,
      zIndex: 51,
      background: COLORS.PINK,
      transition: 'transform .4s, opacity .3s',
      transitionTimingFunction: 'cubic-bezier(0.445, 0.05, 0.55, 0.95);',
    },

  },

  blogItemWrapperInnerHover: {

    '@media (min-width: 769px)': {

      transform: 'scale(1.02)',

      ':before': {
        top: 0,
        right: 0,
        opacity: 1,
        transform: 'translate(8px, -8px)',
      },

      ':after': {
        top: 0,
        right: 0,
        opacity: 1,
        transform: 'translate(8px, -8px)',
      },

    },

  },

  blogItemRibbon: {
    position: POSITION.ABSOLUTE,
    width: 76,
    height: 76,
    top: -10,
    left: -10,
    zIndex: 100,
    overflow: 'hidden',

    ':before': {
      position: POSITION.ABSOLUTE,
      content: '""',
      top: 0,
      right: 0,
      zIndex: -1,
      display: 'block',
      border: `5px solid ${COLORS.PINK_DARK}`,
      borderTopColor: 'transparent',
      borderLeftColor: 'transparent',
    },

    ':after': {
      position: POSITION.ABSOLUTE,
      content: '""',
      bottom: 0,
      left: 0,
      zIndex: -1,
      display: 'block',
      border: `5px solid ${COLORS.PINK_DARK}`,
      borderTopColor: 'transparent',
      borderLeftColor: 'transparent',
    },

  },

  blogItemRibbonText: {
    position: POSITION.ABSOLUTE,
    display: 'block',
    width: 116,
    right: -12,
    top: 15,
    padding: '6px 0',
    backgroundColor: COLORS.PINK,
    boxShadow: '0 5px 10px rgba(0,0,0,.1)',
    color: COLORS.WHITE,
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'center',
    textDecoration: 'none',
    transform: 'rotate(-45deg)',
  },

  blogItemImageWrapper: {
    position: POSITION.RELATIVE,
    width: 'auto',
    height: 0,
    flex: '1 0 50%',
    padding: '0 0 50%',

    ':before': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: 40,
      height: 2,
      bottom: 0,
      left: 0,
      opacity: 0,
      zIndex: 50,
      background: COLORS.PINK,
      transition: 'transform .4s, opacity .3s',
      transitionTimingFunction: 'cubic-bezier(0.445, 0.05, 0.55, 0.95);',
    },

    ':after': {
      position: POSITION.ABSOLUTE,
      content: '""',
      width: 2,
      height: 40,
      bottom: 0,
      left: 0,
      opacity: 0,
      zIndex: 51,
      background: COLORS.PINK,
      transition: 'transform .4s, opacity .3s',
      transitionTimingFunction: 'cubic-bezier(0.445, 0.05, 0.55, 0.95);',
    },

    '@media (max-width: 768px)': {
      flex: '1 0 30%',
      padding: '0 0 30%',
    },

    '@media (max-width: 640px)': {
      flex: '1 0 45%',
      padding: '0 0 45%',
    },

    '@media (max-width: 480px)': {
      flex: '1 0 35%',
      padding: '0 0 40%',
    },

  },

  blogItemImageWrapperHover: {

    '@media (min-width: 769px)': {

      ':before': {
        bottom: 0,
        left: 0,
        opacity: 1,
        transform: 'translate(-8px, 8px)',
      },

      ':after': {
        bottom: 0,
        left: 0,
        opacity: 1,
        transform: 'translate(-8px, 8px)',
      },

    },

  },

  blogItemTag: {
    position: POSITION.ABSOLUTE,
    bottom: 10,
    left: 0,
    fontSize: 10,
    padding: '2px 4px 4px',
    textTransform: 'uppercase',
    color: COLORS.WHITE,
    background: COLORS.PINK,
  },

  blogItemImage: {
    position: POSITION.ABSOLUTE,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    objectFit: 'cover',
  },

  blogItemContent: {
    position: POSITION.RELATIVE,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: 'auto',
    flex: '1 1 50%',
    padding: '20px 10px 10px 20px',
    textDecoration: 'none',

    '@media (max-width: 1000px)': {
      padding: '10px',
    },

    '@media (max-width: 768px)': {
      flex: '1 1 70%',
      padding: '20px',
    },

    '@media (max-width: 640px)': {
      flex: '1 1 55%',
      padding: '10px 10px 10px 20px',
    },

    '@media (max-width: 480px)': {
      flex: '1 1 65%',
      padding: '10px 10px 40px 10px',
    },

  },

  blogItemTitle: {
    display: 'block',
    ...FONTS.OPEN_SANS,
    fontSize: 20,
    fontWeight: 600,
    margin: '0 0 3px',
    color: COLORS.GRAY5,
    textDecoration: 'none',
    lineHeight: 1.2,

    '@media (max-width: 1200px)': {
      margin: 0,
      fontSize: 18,
    },

    '@media (max-width: 1000px)': {
      fontSize: 16,
    },

    '@media (max-width: 900px)': {
      maxWidth: 200,
      overflow: 'hidden',
      fontSize: 14,
      lineHeight: 1.2,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    '@media (max-width: 800px)': {
      maxWidth: 160,
    },

    '@media (max-width: 768px)': {
      maxWidth: 'none',
      overflow: 'visible',
      fontSize: 18,
      margin: '0 0 5px',
      whiteSpace: 'normal',
    },

    '@media (max-width: 640px)': {
      fontSize: 16,
      margin: '0 0 5px',
    },

    '@media (max-width: 480px)': {
      fontSize: 14,
      margin: '0',
    },

  },

  blogItemPublished: {
    display: 'block',
    ...FONTS.OPEN_SANS,
    fontSize: 12,
    fontWeight: 300,
    fontStyle: 'italic',
    margin: '0 0 10px',
    lineHeight: 1.3,
    textDecoration: 'none',
    color: COLORS.GRAY2,

    '@media (max-width: 1200px)': {
      fontSize: 11,
    },

    '@media (max-width: 1000px)': {
      fontSize: 11,
      margin: '0 0 5px',
    },

    '@media (max-width: 900px)': {
      fontSize: 10,
    },

    '@media (max-width: 768px)': {
      fontSize: 13,
      margin: '0 0 10px',
    },

    '@media (max-width: 640px)': {
      fontSize: 12,
      margin: '0 0 5px',
    },

  },

  blogItemLead: {
    display: 'block',
    margin: '0 0 10px',
    ...FONTS.OPEN_SANS,
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 1.3,
    color: COLORS.GRAY5,
    textDecoration: 'none',

    '@media (max-width: 1200px)': {
      fontSize: 14,
    },

    '@media (max-width: 1000px)': {
      fontSize: 13,
    },

    '@media (max-width: 900px)': {
      lineHeight: 1.1,
    },

    '@media (max-width: 800px)': {
      fontSize: 12,
    },

    '@media (max-width: 768px)': {
      fontSize: 14,
      lineHeight: 1.3,
    },

    '@media (max-width: 640px)': {
      fontSize: 12,
      lineHeight: 1.2,
    },

  },

  blogItemLink: {
    position: POSITION.ABSOLUTE,
    display: 'block',
    left: 20,
    bottom: 10,
    padding: '10px 0',
    ...FONTS.OPEN_SANS,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.3,
    textDecoration: 'none',
    color: COLORS.PINK,

    ':hover': {
      textDecoration: 'underline',
    },

    '@media (max-width: 1000px)': {
      bottom: 0,
      left: 10,
    },

    '@media (max-width: 900px)': {
      fontSize: 12,
    },

    '@media (max-width: 768px)': {
      bottom: 10,
      left: 'auto',
      right: 20,
      fontSize: 14,
    },

    '@media (max-width: 640px)': {
      bottom: 0,
      fontSize: 12,
    },

  },

});
