import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import HeroImage from '../components/HeroImage';
import s from './About.style';

class About extends Component {
  state = {};

  render() {
    return (
      <section>
        <HeroImage
          title={ 'Ki áll a The Makeup Lab mögött?' }
          image={ [process.env.PUBLIC_URL + '/images/about-me-hero.jpg'] }
        />
        <div className={ css(s.contentWrapper) }>
          <div className={ css(s.contentWrapperInner) }>
            <p className={ css(s.contentParagraph) }>
              Sminkes annyi van, mint csillag az égen. Nem csodálom, hogy összezavarodtál, hogy
              mégis ki mellett dönts.
            </p>
            <p className={ css(s.contentParagraph) }>
              Nem terhelnélek itt kilométeres szolgáltatási listákkal, mert nem szeretem előre
              megmondani, hogy TE mit szeretnél. Azért iránymutatásnak ott van a legtöbb info a
              ‘Szolgáltatások’ fül alatt. ;)
            </p>
            <p className={ css(s.contentParagraph) }>
              De a lényeg, hogy <b>mindent személyre szabok,</b> legyen szó egy szimpla alkalmi
              sminkről
              vagy akár egy komplett tanfolyamról.
            </p>
            <p className={ css(s.contentParagraph) }>
              Nem ön-megvalósítok, nem ugyanazt a sminket teszem Rád, vagy tanítom le ugyanazokat a
              dolgokat, mint mindenki másnak. <b>Ezek az alkalmak,</b> privát szeánszok <b>nem rólam szólnak, hanem Rólad</b>!
            </p>
            <p className={ css(s.contentParagraph) }>
              Amit ígérhetek, hogy minőségi időt tölthetsz el szuper környezetben egy jó fej
              sminkessel, akit valóban érdekel, hogy ki vagy, honnan jöttél, mit csinálsz. Nem
              mellesleg nem egy másik emberré maszkollak át, hanem legjobb önmagaddá varázsollak. ;)
            </p>
            <p className={ css(s.contentParagraph) }>
              Ja igen, Schmíz Áginak hívnak, én alapítottam a TML-et és azóta is ez az én gyermekem.
              Nevelgetem, regulázom, néha megríkat, de legtöbbször mosolyt csal az arcomra. Semmi
              sem állhat közénk és együtt fejlődünk.
            </p>
            <p className={ css(s.contentParagraph) }>
              Gyere és nézz meg minket alkalomadtán! ;)</p>
            <p className={ css(s.contentParagraph) }>
              Puszi: Ági
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
