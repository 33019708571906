import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import s from './Map.style';

const MAP_STYLE = [
  {
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#f5f5f5',
      },
    ],
  },
  {
    'elementType': 'labels.icon',
    'stylers': [
      {
        'visibility': 'off',
      },
    ],
  },
  {
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#616161',
      },
    ],
  },
  {
    'elementType': 'labels.text.stroke',
    'stylers': [
      {
        'color': '#f5f5f5',
      },
    ],
  },
  {
    'featureType': 'administrative.land_parcel',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#bdbdbd',
      },
    ],
  },
  {
    'featureType': 'poi',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#eeeeee',
      },
    ],
  },
  {
    'featureType': 'poi',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#757575',
      },
    ],
  },
  {
    'featureType': 'poi.park',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#e5e5e5',
      },
    ],
  },
  {
    'featureType': 'poi.park',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#9e9e9e',
      },
    ],
  },
  {
    'featureType': 'road',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#ffffff',
      },
    ],
  },
  {
    'featureType': 'road.arterial',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#757575',
      },
    ],
  },
  {
    'featureType': 'road.highway',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#dadada',
      },
    ],
  },
  {
    'featureType': 'road.highway',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#616161',
      },
    ],
  },
  {
    'featureType': 'road.local',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#9e9e9e',
      },
    ],
  },
  {
    'featureType': 'transit.line',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#e5e5e5',
      },
    ],
  },
  {
    'featureType': 'transit.station',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#eeeeee',
      },
    ],
  },
  {
    'featureType': 'water',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#c9c9c9',
      },
    ],
  },
  {
    'featureType': 'water',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#9e9e9e',
      },
    ],
  },
];

const MapComponent = withScriptjs(withGoogleMap(
  () => <GoogleMap
    defaultZoom={ 17 }
    defaultCenter={ { lat: 47.506637, lng: 19.078212 } }
    defaultOptions={ {
      styles: MAP_STYLE,
      mapTypeControl: false,
    } }
  >
    <Marker
      position={ { lat: 47.505850, lng: 19.074812 } }
      optimized={ false }
      icon={ process.env.PUBLIC_URL + '/images/map-pin-logo.svg' }
    />
  </GoogleMap>,
));


class Map extends Component {
  state = {};

  render() {
    return (
      <div className={ css(s.wrapper) }>
        <MapComponent
          isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCO1uyGIlsz0RdlEf4n-HYJKLfM8aoLVhk&region=HU"
          loadingElement={ <div className={ css(s.loader) } /> }
          containerElement={ <div className={ css(s.containerWrapper) } /> }
          mapElement={ <div className={ css(s.mapWrapper) } /> }
        />
        <div className={ css(s.contactWrapper) }>
          <p className={ css(s.contactInfo) }>
            1071 Budapest,<br />
            Damjanich utca 3.<br />
            Tel: +36/20/294-5757
          </p>
        </div>
      </div>
    );
  }
}

export default Map;
