import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import Icon from './Icon';
import s from './Services.style';

class ServiceItem extends Component {
  state = {
    inited: false,
    hover: false,
  };

  componentDidMount() {
    this.setState({ inited: true });
  }

  onMouseOut = () => {
    this.setState({ hover: false });
  };

  onMouseOver = () => {
    this.setState({ hover: true });
  };

  render() {
    return (
      <div
        className={ css(s.serviceItem) }
        onMouseOver={ this.onMouseOver }
        onMouseOut={ this.onMouseOut }
      >
        <div className={
          css(
            s.serviceItemInner,
            this.state.hover ? s.serviceItemInnerHover : '',
          )
        }>
          <img
            className={ css(s.serviceBg) }
            src={ process.env.PUBLIC_URL + this.props.image }
          />
          <div className={
            css(
              s.serviceBody,
              this.state.hover ? s.serviceBodyHover : '',
            )
          }>
            <h3 className={ css(s.serviceTitle) }>{ this.props.title }</h3>
            <p className={ css(s.serviceLead) }>{ this.props.lead }</p>
            <div
              className={
                css(
                  s.serviceDescription,
                  this.state.hover ? s.serviceDescriptionHover : '',
                )
              }
            >
              { this.props.description }
            </div>
          </div>
          { this.props.time && this.props.price && (
            <div className={ css(s.serviceInfoWrapper) }>
              <div className={ css(s.serviceInfo) }>
                <div className={ css(s.serviceTime) }>
                  <Icon name="time" className={ css(s.serviceLogo) } noStroke={ true } />
                  { this.props.time }
                </div>
                <div className={ css(s.servicePrice) }>
                  <Icon
                    name="credit-card"
                    className={ css(s.serviceLogo, s.serviceLogoRight) }
                    noStroke={ true }
                  />
                  { this.props.price }
                </div>
              </div>
            </div>
          ) }
        </div>
      </div>
    );
  }
}

ServiceItem.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  lead: PropTypes.string,
  time: PropTypes.string,
  price: PropTypes.string,
  description: PropTypes.element,
};

export default ServiceItem;
