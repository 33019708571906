import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import { Link } from 'react-router-dom';
import s from './WorksList.style';

class WorksList extends Component {
  render() {
    return (
      <section>
        <div className={ css(s.contentWrapper) }>
          <Link
            to={ '/munkak/tanuloim-munkai/' }
            className={ css(s.workCategory) }
          >
            <h3 className={ css(s.workTitle) }>Tanulóim munkái</h3>
            <img alt="Tanulóim munkái" src={ [process.env.PUBLIC_URL + '/images/works/student-cover.jpg'] } />
          </Link>
          <Link
            to={ '/munkak/divat/' }
            className={ css(s.workCategory) }
          >
            <h3 className={ css(s.workTitle) }>Divat</h3>
            <img alt="Divat" src={ [process.env.PUBLIC_URL + '/images/works/fashion-cover.jpg'] } />
          </Link>
          <Link
            to={ '/munkak/alkalmi-sminkek/' }
            className={ css(s.workCategory) }
          >
            <h3 className={ css(s.workTitle) }>Alkalmi sminkek</h3>
            <img alt="Alkalmi sminkek" src={ [process.env.PUBLIC_URL + '/images/works/colorful-cover.jpg'] } />
          </Link>
          <Link
            to={ '/munkak/eskuvoi-munkaim/' }
            className={ css(s.workCategory) }
          >
            <h3 className={ css(s.workTitle) }>Esküvői munkáim</h3>
            <img alt="Esküvői munkáim" src={ [process.env.PUBLIC_URL + '/images/works/bridal-cover.jpg'] } />
          </Link>
        </div>
      </section>
    );
  }
}

export default WorksList;
