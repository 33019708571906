import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import Swiper from 'react-id-swiper/lib/custom';
import Icon from './Icon';
import s from './HomeCarousel.style';

class HomeCarousel extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.goNext = this.goNext.bind(this);
    this.goPrev = this.goPrev.bind(this);
    this.swiperItem = React.createRef();
  }

  goNext() {
    if (this.swiperItem) {
      this.swiperItem.current.swiper.slideNext();
    }
  }

  goPrev() {
    if (this.swiperItem) {
      this.swiperItem.current.swiper.slidePrev();
    }
  }

  render() {
    const params = {
      speed: 800,
      containerClass: css(s.carouselWrapper),
      wrapperClass: css(s.swiperWrapper),
      slideClass: css(s.slide),
      slidesPerView: 1,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    };

    return (
      <div className={ css(s.heroWrapper) }>
        <Swiper { ...params } ref={ this.swiperItem }>
          <a
            href="https://www.youtube.com/channel/UCWKr7IebgUjkRAQmPOKWNcw/"
            className={ css(s.slide) }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="Kövess a YouTube csatornámon is!"
              className={ css(s.slideBg) }
              src={ process.env.PUBLIC_URL + '/images/hero-youtube.jpg' }
            />
            <img
              alt="Kövess a YouTube csatornámon is!"
              className={ css(s.slideBgTablet) }
              src={ process.env.PUBLIC_URL + '/images/hero-youtube-tablet.jpg' }
            />
          </a>
          <a
            href="https://www.instagram.com/themakeuplab_hu/"
            className={ css(s.slide) }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="Sminköznapok"
              className={ css(s.slideBg) }
              src={ process.env.PUBLIC_URL + '/images/hero-sminkoznap.jpg' }
            />
            <img
              alt="Sminköznapok"
              className={ css(s.slideBgTablet) }
              src={ process.env.PUBLIC_URL + '/images/hero-sminkoznap-tablet.jpg' }
            />
          </a>
          <div className={ css(s.slide) }>
            <div className={ css(s.logoWrapper) }>
              <Icon name="logo" className={ css(s.logo) } />
            </div>
            <img
              alt=""
              className={ css(s.slideBg) }
              src={ process.env.PUBLIC_URL + '/images/hero-1.jpg' }
            />
          </div>
          <div className={ css(s.slide) }>
            <img
              alt="The Makeup Lab stúdió"
              className={ css(s.slideBg) }
              src={ process.env.PUBLIC_URL + '/images/hero-studio.jpg' }
            />
            <img
              alt="The Makeup Lab stúdió"
              className={ css(s.slideBgTablet) }
              src={ process.env.PUBLIC_URL + '/images/hero-studio-tablet.jpg' }
            />
          </div>
        </Swiper>
        <button
          className={ css(s.carouselButton, s.carouselButtonLeft) }
          onClick={ this.goPrev }>Prev
        </button>
        <button
          className={ css(s.carouselButton, s.carouselButtonRight) }
          onClick={ this.goNext }>Next
        </button>
      </div>
    );
  }
}

export default HomeCarousel;
