import { POSITION, FONTS, Size, COLORS } from '../styles';
import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
  header: {
    position: POSITION.FIXED,
    ...Size('100vw', '100vh'),
    padding: 0,
    margin: 0,
    overflow: 'hidden',
  },

  page: {
    position: POSITION.RELATIVE,
    padding: 0,
    margin: 0,
    ...FONTS.OPEN_SANS,
    color: COLORS.BLACK,
  },

  mainWrapper: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    marginBottom: 110,
    background: COLORS.WHITE,
    boxShadow: '0 0 30px 0 rgba(0, 0, 0, .3)',

    '@media (max-width: 768px)': {
      marginBottom: 0,
    },

  },
});
